<template>
  <div class="q-list-wrap">
    <div class="q-list-relative-wrap">
      <template v-if="this.isLoadingList">
        <div class="q-list-loading">
          <b-spinner
              style="width: 3rem; height: 3rem;"
              variant="primary"
              label="Spinning"
          ></b-spinner>
        </div>
      </template>
      <template v-else>
        <div class="q-list-inner-wrap">
          <div class="q-list">
            <draggable
                class="vue-draggable-next"
                tag="ul"
                :list="this.filteredPrbList"
                v-bind="this.dragOptions"
                @start="() => this.onStartDragging()"
                @end="() => this.onEndDragging()"
                @change="this.onChangeDragging">
              <transition-group type="transition" name="flip-list">
                <li v-for="(trItem, trIndex) in this.filteredPrbList"
                  class="list-group-item list-item"
                  :key="trItem[0].order"
                >
                  <h1>{{ trIndex + 1 }}.</h1>
                  <div class="item-content">
                    <img :src="trItem[0].imgUrl" alt=""/>
                  </div>
                  <div class="item-pannel">
                    <CustomButton
                      title="추가"
                      :addClass="'color-text'"
                      @click="() => this.onAddNewList(trItem)"
                    />
                  </div>
                </li>
              </transition-group>
            </draggable>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ListArrayBodyTr from "@/components/ListArrayBodyTr.vue";
import { VueDraggableNext } from "vue-draggable-next";
import CustomButton from "@/components/CustomButton.vue";
import ProblemService from "@/services/problem.service";
import utils from "@/utils";

export default {
  name: 'EditStep2MyPrbTab',
  components: {
    CustomButton,
    draggable: VueDraggableNext,
    ListArrayBodyTr,
  },
  props: {
    resultList: Array,
    onStartDragging: Function,
    onEndDragging: Function,
    onChangeDragging: Function,
    onAddNewList: Function,
  },
  watch: {
  },
  mounted() {
    this.initList();
  },
  data() {
    return {
      isLoadingList: true,
      prbList: [],
    };
  },
  methods: {
    async initList() {
      this.isLoadingList = true;
      this.prbList = [];

      let res = await ProblemService.getWorksheetTabMyProblems();
      if (res) {
        const problems = res['problems'] ?? [];
        let index = 0;
        this.prbList = problems.map(problem => {
          return [
            Object.assign({
              'order': 1 + index++,
              'value': '',
              'imgUrl': utils.getServerMediaPath(problem.problem_img),
              'onClick': () => {},
            }, problem),
            {'value': problem.type, 'id': problem.id, 'onClick': () => {}},
            {'value': problem.difficulty, 'id': problem.id, 'onClick': () => {}},
            {'value': problem.pattern_name,'id': problem.id, 'onClick': () => {}},
            {'value': '', 'id': problem.id, 'onClick': () => {}}
          ];
        });
      }

      this.isLoadingList = false;
    },
  },
  computed: {
    filteredPrbList() {
      return this.prbList.filter(i => {
        return this.resultList.findIndex(ri => ri[0].id == i[0].id) < 0
      }).map(item => Object.assign({}, item))
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";
</style>
