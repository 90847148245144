<template>
    <div :class="['popup', addPopupClass, { hide: !isVisible }]"
         :style="addPopupStyle"
    >
      <div class="popup-shadow" @click="this.onClose"></div>
      <div :class="['popup-body', addPopupBodyClass, {'table-popup-body': isTablePopupBody}]">
        <div class="popup-title" v-if="popupTitle">
            <div 
                v-if="isTitleExclamationMark"
                class="exclamation-mark"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" data-svg-name="alert_error_fill" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(255, 112, 125);"><path fill="silver" d="M8 1.333C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zm.667 10H7.334V10h1.333v1.333zm0-2.666H7.334v-4h1.333v4z"></path></svg>
            </div>
            {{ popupTitle }}
        </div>
  
        <div :class="['body-contents', addBodyContentsClass]" :style="[addBodyContentsStyle]">
          {{ contents }}
            <slot name="bodyContents"></slot>
        </div>
  
        <slot name="bodyInnerContents"></slot>
  
        <div
            v-if="onConfirm || onCancel"
            class="btn-list">
            <button
                v-if="onConfirm"
                @click="onConfirmEvent"
                >
                {{ confirmBtnText }}
            </button>
            <button
                v-if="onCancel"
                @click="onCancelEvent"
                class="cancel-btn"
                >
                {{ cancelBtnText }}
            </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Popup',
    components: {
    },
    props: {
      isTitleExclamationMark: Boolean,
      isVisible: Boolean,
      contents: String,
      isShowConfirmButton: Boolean,
      onClose: Function,
      isTablePopupBody: Boolean,
      popupTitle: String,
      addPopupClass: String,
      addPopupStyle: String,
      addBodyContentsClass: String,
      addBodyContentsStyle: String,
      addPopupBodyClass: String,
      onConfirm: Function,
      confirmBtnText: {type: String, default: '확인'},
      onCancel: Function,
      cancelBtnText: {type: String, default: '취소'},
    },
    methods: {
      onConfirmEvent() {
        if (this.onConfirm) {
          this.onConfirm()
        }
        if (this.onClose) {
          this.onClose()
        }
      },
      onCancelEvent() {
        if (this.onCancel) {
          this.onCancel()
        }
        if (this.onClose) {
          this.onClose()
        }
      },
    },
  }
  </script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: calc(100% - 40px - 40px);
    max-height: 100%;
    margin: auto;
    padding: 24px;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    &.table-popup-body {
      max-width: calc(1345px);
      width: 100%;
      box-sizing: border-box;
      padding: 0;
    }
    &.inner-popup-body {
      max-width: calc(1345px);
      width: 100%;
      box-sizing: border-box;
      padding: 0;
    }
    .popup-title {
        display: flex;
        margin-left: 8px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: rgb(255, 112, 125);
        font-weight: 500;
        margin-bottom: 10px;
        gap: 10px;
        align-items: center;
        .exclamation-mark {
            width: 24px;
            height: 24px;
            align-self: center;
            svg {
                width: 100%;
                height: 100%;
                color: rgb(255, 112, 125);
                path {
                    fill: currentColor;
                }
            }
        }
    }
    .body-contents {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
    }
    .btn-list {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: center;
        margin-top: 24px;
        &>button {
            cursor: pointer;
            width: 100px;
            height: 40px;
            border-radius: 5px;
            transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
            background-color: rgb(255, 112, 125);
            color: white;
            font-weight: 500;
            &.cancel-btn {
                background-color: rgb(245, 245, 245);
                color: rgb(92, 92, 92);
            }
        }
    }
  }
}
</style>
  