<template>
  <div class="edit-step-2-wrap">
    <div class="mypage-tab-wrap">
      <div v-for="(tab, index) in this.tabData.tabs" :key="index"
        :class="['tab-item', {'active': this.tabData.selectTabIndex == index}]"
        @click="() => { onChangeTab(index) }">
        {{ tab.title }}
      </div>
    </div>
    <div class="mypage-tab-body-list">
      <div class="mypage-tab-body-wrap">
        <template v-if="this.tabData.selectTabIndex == 0"> <!-- 학습지 상세 -->
          <EditStep2DetailPrbTab
            :title="this.detail.listData.title ?? ''"
            :heads="this.detail.listData.heads ?? []"
            :trList="this.detail.listData.trList ?? []"
            :on-start-dragging="() => this.isDragging = true"
            :on-end-dragging="() => this.isDragging = false"
            :on-change-dragging="this.onChangeDraggableListByDetail"
          />
        </template>
        <template v-else-if="this.tabData.selectTabIndex == 1"> <!-- 새 문제 추가 -->
          <EditStep2NewPrbTab
            :problem-ids="this.problemIds"
            :pattern-ids="this.patternIds"
            :result-list="this.resultList"
            :on-start-dragging="() => this.isDragging = true"
            :on-end-dragging="() => this.isDragging = false"
            :on-change-dragging="this.onChangeDraggableListByNew"
            :on-add-new-list="this.onAddNewList"
          />
        </template>
        <template v-else-if="this.tabData.selectTabIndex == 2"> <!-- 유사문제 -->
          <EditStep2SimilarPrbTab
            :similar-list="this.similarList"
            :result-list="this.resultList"
            :is-selected-similar-prb="this.resultList.findIndex(i => i[0].isSelectSimilar) >= 0"
            :prb-num="this.resultList.findIndex(i => i[0].isSelectSimilar) + 1"
            :ptn-nm="this.selectedSimilarPrb ? this.selectedSimilarPrb[3].value : ''"
            :on-start-dragging="() => this.isDragging = true"
            :on-end-dragging="() => this.isDragging = false"
            :on-change-dragging="this.onChangeDraggableListBySimilar"
            :on-add-new-list="this.onAddNewList"
          />
        </template>
        <template v-else-if="this.tabData.selectTabIndex == 3"> <!-- 나만의 문제 -->
          <EditStep2MyPrbTab
            :result-list="this.resultList"
            :on-start-dragging="() => this.isDragging = true"
            :on-end-dragging="() => this.isDragging = false"
            :on-change-dragging="this.onChangeDraggableListByMyPrb"
            :on-add-new-list="this.onAddNewList"
          />
        </template>
      </div>

      <div class="mypage-tab-body-wrap">
        <Filter
          :filterData="this.detail.filterData"
        />

        <div class="q-list-wrap">
          <div class="q-list-relative-wrap">
            <div class="q-list-inner-wrap">
              <div class="q-list">
                <draggable
                    class="vue-draggable-next"
                    tag="ul"
                    :list="this.resultList"
                    v-bind="this.dragOptions"
                    @start="this.isDragging = true"
                    @end="this.isDragging = false"
                    @change="this.onChangeDraggableListByResult"
                    id="resultList"
                >
                  <transition-group type="transition" name="flip-list">
                    <li
                        class="list-group-item list-item"
                        v-for="(trItem, trIndex) in this.resultList"
                        :key="trItem[0].order"
                    >
                      <h1>{{ trIndex + 1 }}.</h1>
                      <div class="item-content">
                        <img :src="trItem[0].imgUrl" alt=""/>
                      </div>
                      <div class="item-pannel">
                        <CustomButton
                            :title="'삭제'"
                            :addClass="'gray color-text'"
                            @click="() => this.onDeleteTrList(trIndex)"
                        />
                        <CustomButton
                            :title="'유사문제'"
                            :addClass="'color-text'"
                            :isChecked="trItem[0].isSelectSimilar"
                            @click="() => this.onSelectSimilar(trItem)"
                        />
                        <CustomButton
                            :title="'신고하기'"
                            :addClass="'red color-text'"
                            @click="() => this.onClickReport(trItem)"
                        />
                        <CustomButton
                            :title="'제안하기'"
                            :addClass="'green color-text'"
                            @click="() => this.onClickSuggest(trItem)"
                        />
                      </div>
                    </li>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filter from './Filter.vue'
import ListArrayBodyTr from './ListArrayBodyTr.vue'
import { VueDraggableNext } from "vue-draggable-next";
import utils from '@/utils'
import models from '@/models'
import ProblemService from '@/services/problem.service'
import CustomButton from "@/components/CustomButton.vue";
import EditStep2MyPrbTab from "@/components/EditStep2MyPrbTab.vue";
import EditStep2NewPrbTab from "@/components/EditStep2NewPrbTab.vue";
import EditStep2SimilarPrbTab from "@/components/EditStep2SimilarPrbTab.vue";
import EditStep2DetailPrbTab from "@/components/EditStep2DetailTab.vue";

export default {
  name: 'EditStep2',
  components: {
    EditStep2DetailPrbTab,
    EditStep2SimilarPrbTab,
    EditStep2NewPrbTab,
    EditStep2MyPrbTab,
    CustomButton,
    Filter,
    ListArrayBodyTr,
    draggable: VueDraggableNext,
  },
  props: {
    isEdit: Boolean,
    isWorkbook: Boolean,
    onChangeStep: Function,
    problems: Array,
    onChangeResultProblems: Function,
    searchProblems: Array,
    onOpenReportPopup: Function,
    onOpenSuggestPopup: Function,
    limitProblemCnt: { type: Number, default: null },
  },
  watch: {
    problems() {
      this.initList()
    },
    searchProblems() {
      this.initList()
    },
    resultList() {
      this.emitResultProblems()
    },
  },
  mounted() {
    this.initList()
  },
  data() {
    const sortOptions = [
      {'value': 1, 'name': '유형 오름차순', 'isSelected': !this.isEdit},
      {'value': 2, 'name': '유형 내림차순', 'isSelected': false},
      {'value': 3, 'name': '난이도 오름차순', 'isSelected': false},
      {'value': 4, 'name': '난이도 내림차순', 'isSelected': false},
      {'value': 5, 'name': '사용자 정렬', 'isSelected': this.isEdit},
    ]

    return {
      isDragging: false,

      tabData: {
        selectTabIndex: 0,
        tabs: [
          {title: this.isWorkbook ? '교재 상세' : '학습지 상세'},
          {title: '새 문제 추가'},
          {title: '유사문제'},
          {title: '나만의 문제'},
        ],
      },

      originList: [],

      detail: {
        listData: {
          title : '총 문제 수       0개',
          heads: [
            {title: '번호', type: 'string', addClass: 'w-50px'},
            {title: '문제 타입', type: 'string', addClass: 'w-75px'},
            {title: '난이도', type: 'string', addClass: 'w-50px'},
            {title: '유형명', type: 'string', addClass: 'flex-1'},
            {title: '순서 변경', type: 'order-btn', addClass: 'w-75px'},
          ],
          trList: [],
        },

        filterData: {
          title: '문항 정렬',
          list: [
            {type: 'select', addClass: 'w-145px', name: '문제 정렬 선택', options: sortOptions, onChange: this.onChangeSort},
          ],
          searchInput: {isHidden: true},
        },
      },
      similarList: [],

      resultList: [],
      reportItem: null,
    };
  },
  methods: {
    emitResultProblems() {
      const resultProblems = this.resultList.map(i => {
        return Object.assign({}, i[0])
      })

      this.onChangeResultProblems(resultProblems)
    },
    changeSortToCustom() {
      this.onChangeSort(5)
    },
    onChangeSort(value) {
      const options = this.detail.filterData.list[0].options
      options.forEach(i => i.isSelected = i.value == value)

      this.sortList()
    },
    sortList() {
      const options = this.detail.filterData.list[0].options
      const selectOption = options.find(i => i.isSelected)
      const selectValue = selectOption?.value

      const detailList = this.detail.listData.trList
      const resultList = this.resultList

      const difficulty_list = models.difficultyList()
      resultList.sort(function(a, b) {
        if ([1, 2].includes(selectValue)) { // 유형 오름차순, 내림차순
          if (a[0].pattern_name > b[0].pattern_name) {
            return selectValue == 1 ? 1 : -1
          } else if (a[0].pattern_name < b[0].pattern_name) {
            return selectValue == 1 ? -1 : 1
          }
        } else if ([3, 4].includes(selectValue)) { // 난이도 오름차순, 내림차순
          const aIndex = difficulty_list.find(i => i == a[0].difficulty)
          const bIndex = difficulty_list.find(i => i == b[0].difficulty)

          if (aIndex > bIndex) {
            return selectValue == 3 ? -1 : 1
          } else if (aIndex < bIndex) {
            return selectValue == 3 ? 1 : -1
          }
        }
        return 0
      })

      detailList.splice(0, detailList.length)
      const clonedResultList = resultList.map(item => Object.assign({}, item))
      Array.prototype.push.apply(detailList, clonedResultList);

      this.emitResultProblems()
    },
    refreshTotalProblemCnt() {
      this.detail.listData.title = `총 문제 수       ${Number(this.detail.listData.trList.length)}개`
    },
    initList() {
      this.onChangeTab(0)

      const originList = this.problems.map((p, index) => {
        return [
          Object.assign({
            'value': '',
            'imgUrl': utils.getServerMediaPath(p.problem_img),
            'onClick': () => {},
          }, p),
          {'value': p.type, 'id': p.id, 'onClick': () => {}},
          {'value': p.difficulty, 'id': p.id, 'onClick': () => {}},
          {'value': p.pattern_name,'id': p.id, 'onClick': () => {}},
          {'value': '', 'id': p.id, 'onClick': () => {}}
        ]
      })
      originList.forEach((item, idx) => item[0].order = idx + 1)
      this.originList = originList.map(item => Object.assign({}, item))
      this.detail.listData.trList = originList.map(item => Object.assign({}, item))
      this.resultList = originList.map(item => Object.assign({}, item))

      this.refreshTotalProblemCnt();
      this.sortList();
    },
    onChangeTab(index) {
      this.tabData.selectTabIndex = index
    },
    onChangeDraggableListByDetail(event) {
      this.onChangeDraggableList(
          this.detail.listData.trList,
          this.resultList,
          event
      )
    },
    onChangeDraggableListByResult(event) {
      this.onChangeDraggableList(
          this.resultList,
          this.detail.listData.trList,
          event
      )
    },
    onChangeDraggableListByNew(event) {
      const moved = event.moved

      if (moved) {
        this.$toast.info('새 문제 리스트 순서는 수정할 수 없습니다')
      }
    },
    onChangeDraggableListBySimilar(event) {
      const moved = event.moved

      if (moved) {
        this.$toast.info('유사문제 리스트 순서는 수정할 수 없습니다')
      }
    },
    onChangeDraggableListByMyPrb(event) {
      const moved = event.moved

      if (moved) {
        this.$toast.info('나만의 문제 리스트 순서는 수정할 수 없습니다')
      }
    },
    onChangeDraggableList(currentList, targetList, event) {
      let element, elementId, newIndex, findIndex, clonedCurList

      const moved = event.moved
      const added = event.added

      if (moved) {
        targetList.splice(0, targetList.length)
        clonedCurList = currentList.map(item => Object.assign({}, item))
        Array.prototype.push.apply(targetList, clonedCurList);
      }
      if (added) {
        element = added.element
        elementId = element[0].id
        newIndex = added.newIndex

        findIndex = currentList.findIndex((i, index) => i[0].id == elementId && index != newIndex)
        if (findIndex > -1) {
          currentList.splice(findIndex, 1)
        }

        targetList.splice(0, targetList.length)
        clonedCurList = currentList.map(item => Object.assign({}, item))
        Array.prototype.push.apply(targetList, clonedCurList);
      }

      this.changeSortToCustom()
    },
    onDeleteTrList(trIndex) {
      this.detail.listData.trList.splice(trIndex, 1)
      this.resultList.splice(trIndex, 1)

      this.refreshTotalProblemCnt()
      this.emitResultProblems()
    },
    onAddNewList(trItem) {
      const totalCnt = this.detail.listData.trList.length;
      const limitProblemCnt = this.limitProblemCnt;
      if (limitProblemCnt != null && totalCnt >= limitProblemCnt) {
        this.$toast.error(`문항 수 ${limitProblemCnt}개까지 제한된 계정입니다`);
        return;
      }

      this.detail.listData.trList.push(trItem)
      this.resultList.push(trItem)

      setTimeout(() => {
        const resultList = document.querySelector('#resultList')
        const lastChild = resultList.children[resultList.children.length - 1]
        lastChild.scrollIntoView({behavior: 'smooth'})
      }, 0);

      this.refreshTotalProblemCnt()
      this.emitResultProblems()
    },
    async onSelectSimilar(trItem) {
      const isBeforeSelected = trItem[0].isSelectSimilar ?? false
      const isSelected = !isBeforeSelected

      this.resultList.forEach(i => i[0].isSelectSimilar = false)
      trItem[0].isSelectSimilar = isSelected

      this.similarList = []

      if (isSelected) {
        this.tabData.selectTabIndex = 2
        const patternName = trItem[3].value
        let res = await ProblemService.getProblems({
          'pattern_name': patternName,
        })
        if (res) {
          const similarList = res.map(p => {
            return [
              Object.assign({
                'value': '',
                'imgUrl': utils.getServerMediaPath(p.problem_img),
                'onClick': () => {},
              }, p),
              {'value': p.type, 'id': p.id, 'onClick': () => {}},
              {'value': p.difficulty, 'id': p.id, 'onClick': () => {}},
              {'value': p.pattern_name,'id': p.id, 'onClick': () => {}},
              {'value': '', 'id': p.id, 'onClick': () => {}}
            ]
          })
          similarList.forEach((item, idx) => item[0].order = idx + 1)
          this.similarList = similarList
        }
      }
    },
    async onClickReport(trItem) {
      console.log(trItem)
      this.onOpenReportPopup({
        id: trItem[0].id,
      })
    },
    async onClickSuggest(trItem) {
      this.onOpenSuggestPopup({
        id: trItem[0].id,
      })
    },
  },
  computed: {
    selectedSimilarPrb() { return this.resultList.find(i => i[0].isSelectSimilar) },
    problemIds() { return this.problems.map((p) => p.id) },
    patternIds() {
      return this.problems.reduce((acc, cur) => {
        const patternId = cur.pattern;
        if (!acc.includes(patternId)) {
          acc.push(patternId);
        }
        return acc;
      }, []);
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.edit-step-2-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .mypage-tab-wrap {
    display: flex;
    .tab-item {
      max-width: 150px;
    }
  }
  .mypage-tab-body-list {
    width: 100%;
    height: calc(100% - 52px);
    display: flex;
    .mypage-tab-body-wrap {
      padding: 38px 31px 30px 34px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-right: 0;
      &:first-child {
        max-width: 564px;
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-right: 1px solid #{$defaultColor};
      }
    }
  }
}
</style>
