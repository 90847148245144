<template>
  <div class="section-3-wrap">
    <div class="v2-base-container">
      <TitleTooltip
          title="대단원별 성취도 분석"
          :tooltip="'과학 과목의 대단원 별 학습 참여율과 지도 필요 학생을 알 수 있습니다'"
      />
      <template v-if="this.errorMsg !== null">
        <ErrorMsgBox :contents="this.errorMsg"/>
      </template>
      <template v-else-if="this.isLoading">
        <LoadingBox size="25px" style="margin: 10px auto"/>
      </template>
      <template v-else>
        <div class="s-tab-list">
          <template v-for="(resultItem, index) in this.resultItems" :key="index">
            <div :class="['tab-item', {'active': index === this.selectTabIdx}]"
                 @click="() => this.selectTabIdx = index">
              <p>{{ resultItem.branch_name }}</p>
              <div class="ti-divider"></div>
            </div>
          </template>
        </div>
        <div class="s-body-list">
          <template v-for="(chapterItem, index) in this.selectChapterItems" :key="index">
            <div class="list-item"
                 @click="() => this.showAnalysisDetailPopup(chapterItem)">
              <div class="li-num">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="li-title">
                <p>{{ chapterItem.ptn_nm }}</p>
              </div>
              <div class="li-progress-wrap">
                <div class="pb-wrap">
                  <div class="pbw-inner" :style="[{'width': `${chapterItem.learn_rt}%`}]"></div>
                </div>
                <p>학습 참여율</p>
                <span>{{ chapterItem.learn_rt }}%</span>
              </div>
              <div class="li-divider"></div>
              <div class="li-guid-wrap">
                <p>지도 필요학생</p>
                <span>{{ chapterItem.nd_gd_std_au_ids.length }}명</span>
              </div>
              <img class="li-arrow" src="@/assets/images/arrow-right-2.png" alt="">
            </div>
          </template>
        </div>
        <!--              TODO: -->
        <!--              <button class="s-button">-->
        <!--                <img src="@/assets/images/edit.png" alt="">-->
        <!--                <span>학습 유도하기</span>-->
        <!--              </button>-->
      </template>
    </div>
  </div>
  <div class="section-3-divider"></div>

  <Teleport to="body">
    <AnalysisDetailPopup
      :is-visible="this.analysisDetailPopupItem !== null"
      :on-close="() => this.analysisDetailPopupItem = null"
      :chapter-data="this.analysisDetailPopupItem"
    />
  </Teleport>
</template>

<script>
import TitleTooltip from "@/components/TitleTooltip.vue";
import AcademyService from "@/services/academy.service";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import AnalysisDetailPopup from "@/components/AnalysisDetailPopup.vue";

export default {
  components: {
    AnalysisDetailPopup,
    LoadingBox,
    ErrorMsgBox,
    TitleTooltip,
  },
  props: {
    academyClassId: Number,
  },
  watch: {
    academyClassId() {
      if (this.academyClassId !== null) {
        this.initData();
      }
    },
  },
  data() {
    return {
      errorMsg: null,
      isLoading: true,
      selectTabIdx: 0,
      resultItems: [],
      analysisDetailPopupItem: null,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    showAnalysisDetailPopup(chapterItem) {
      const academyClassId = this.academyClassId;
      if (academyClassId === null) { this.$toast.error('반을 먼저 선택해주세요'); return; }

      const resultItems = this.resultItems;
      const selectTabIdx = this.selectTabIdx;
      if (resultItems.length <= selectTabIdx) { this.$toast.error('데이터 처리 중 오류가 발생하였습니다'); return; }
      const resultItem = resultItems[selectTabIdx];

      this.analysisDetailPopupItem = {
        'branchName': resultItem['branch_name'],
        'chapterPtnId': chapterItem.unit_1_ptn_id,
        'academyClassId': this.academyClassId,
      };
    },
    async initData() {
      const academyClassId = this.academyClassId;
      if (academyClassId === null) { this.errorMsg = '반을 먼저 선택해주세요'; return; }

      this.isLoading = true;
      let isError = false;
      await Promise.all([
        AcademyService.getTeacherDashboardAnalysis(academyClassId).then(res => {
          if (res) {
            this.resultItems = res.result_items;
          } else isError = true;
        }),
      ]);
      if (isError) this.errorMsg = '데이터를 불러오는 중 오류가 발생하였습니다';
      this.isLoading = false;
    },
  },
  computed: {
    selectChapterItems() {
      const resultItems = this.resultItems;
      const selectTabIdx = this.selectTabIdx;
      if (resultItems.length <= selectTabIdx) return [];
      return resultItems[selectTabIdx].chapter_items;
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.section-3-wrap {
  width: 100%;
  display: flex;
  background-color: white;
  padding: 20px 0;
  .v2-base-container {
    flex-direction: column;
    .s-tab-list {
      margin-top: 20px;
      width: 100%;
      display: flex;
      padding: 0 5px;
      .tab-item {
        cursor: pointer;
        padding: 0 5px;
        &>p {
          font-weight: 400;
          font-size: 13px;
          color: #797979;
          @include tablet {
            font-size: 12px;
          }
        }
        .ti-divider {
          margin-top: 10px;
          width: 100%;
          height: 2px;
          @include tablet {
            margin-top: 5px;
          }
        }
        &.active {
          &>p {
            color: #008ED5;
          }
          .ti-divider {
            background-color: #008ED5;
          }
        }
      }
    }
    .s-body-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      margin-top: 10px;
      @include tablet {
        padding: 0;
        margin-top: 5px;
      }
      .list-item {
        width: 100%;
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid #DBDBDB;
        align-items: center;
        gap: 18px;
        cursor: pointer;
        @include tablet {
          padding: 7px 0;
          gap: 12px;
        }
        @include mobile {
          flex-wrap: wrap;
        }
        .li-num {
          width: 35px;
          height: 35px;
          border: 2px solid #008ED5;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include tablet {
            width: 20px;
            height: 20px;
          }
          &>p {
            font-weight: 800;
            font-size: 20px;
            color: #008ED5;
            @include tablet {
              font-size: 14px;
            }
          }
        }
        .li-title {
          flex: 1;
          display: flex;
          align-items: center;
          &>p {
            font-weight: 600;
            font-size: 15px;
            color: #000000;
            @include tablet {
              font-size: 13px;
            }
          }
        }
        .li-progress-wrap {
          display: flex;
          align-items: center;
          @include mobile {
            width: 100%;
          }
          .pb-wrap {
            width: 100px;
            height: 5px;
            border-radius: 15px;
            overflow: hidden;
            background-color: #DBDBDB;
            @include tablet {
              width: 70px;
              height: 3px;
            }
            @include mobile {
              width: unset;
              flex: 1;
            }
            .pbw-inner {
              height: 100%;
              border-radius: inherit;
              background-color: #008ED5;
            }
          }
          &>p {
            padding-left: 18px;
            font-weight: 500;
            font-size: 13px;
            color: #000000;
            @include tablet {
              padding-left: 12px;
              font-size: 11px;
            }
          }
          &>span {
            padding-left: 10px;
            font-weight: 600;
            font-size: 15px;
            color: #008ED5;
            @include tablet {
              padding-left: 6px;
              font-size: 12px;
            }
          }
        }
        .li-divider {
          width: 1px;
          background-color: #DBDBDB;
          align-self: stretch;
          @include mobile {
            display: none;
          }
        }
        .li-guid-wrap {
          display: flex;
          align-items: center;
          gap: 10px;
          @include tablet {
            gap: 6px;
          }
          @include mobile {
            flex: 1;
          }
          &>p {
            font-weight: 500;
            font-size: 13px;
            color: #000000;
            @include tablet {
              font-size: 11px;
            }
          }
          &>span {
            font-weight: 600;
            font-size: 15px;
            color: #797979;
            @include tablet {
              font-size: 13px;
            }
          }
        }
        .li-arrow {
          width: 12px;
          height: 24px;
          object-fit: contain;
          @include tablet {
            width: 10px;
            height: 20px;
          }
        }
      }
    }
    .s-button {
      margin-top: 30px;
      margin-left: auto;
      background-color: #008ED5;
      border-radius: 30px;
      cursor: pointer;
      gap: 10px;
      align-items: center;
      padding: 10px 20px;
      display: flex;
      @include tablet {
        margin-top: 20px;
        gap: 7px;
        padding: 7px 15px;
      }
      @include mobile {
        padding: 5px 12px;
      }
      &>img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        @include tablet {
          width: 20px;
          height: 20px;
        }
        @include mobile {
          width: 12px;
          height: 12px;
        }
      }
      &>span {
        font-weight: 800;
        font-size: 20px;
        color: white;
        margin-top: 5px;
        @include tablet {
          font-size: 16px;
          margin-top: 3px;
        }
        @include mobile {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
