<template>
  <div :class="['popup', {'hide': !this.isVisible}]">
    <div class="popup-shadow" @click="this.onClose"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        <h1>공유하기</h1>
        <h2>{{ this.menuTitle }}</h2>
        <button class="close-btn" @click="this.onClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>
      <div class="body-contents" ref="bodyContents">
        <h2>* 하위 메뉴도 함께 공유됩니다</h2>
        <div class="bc-box-wrap">
          <h1>공유할 학생 선택</h1>
          <div class="bw-tab-list">
            <div class="tl-side-wrap">
              <div v-for="(studentTab, index) in this.studentTabs" :key="index"
                   :class="['tls-item', {'active': this.selectStudentTabIndex == index}]"
                   @click="() => this.onClickSelectStudentTab(index)">
                {{ studentTab }}
              </div>
            </div>
            <div class="tl-side-wrap"></div>
          </div>
          <div class="bw-search-wrap">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="search" class="react-time-search-icon css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(159, 159, 159);"><path fill="#9F9F9F" d="M12.917 11.667h-.659l-.233-.225c.817-.95 1.308-2.184 1.308-3.525 0-2.992-2.425-5.417-5.416-5.417C4.925 2.5 2.5 4.925 2.5 7.917c0 2.991 2.425 5.416 5.417 5.416 1.341 0 2.575-.491 3.525-1.308l.225.233v.659l4.166 4.158 1.242-1.242-4.158-4.166zm-5 0c-2.075 0-3.75-1.675-3.75-3.75s1.675-3.75 3.75-3.75 3.75 1.675 3.75 3.75-1.675 3.75-3.75 3.75z"></path></svg>
            <input type="text" placeholder="학생 이름 검색" v-model="this.searchStudentName"/>
            <button @click="() => this.searchStudentName = null">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: rgb(192, 192, 192);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
            </button>
          </div>
          <div class="bw-side-list-wrap">
            <div v-for="(boxIndex, index) in [...Array(2).keys()]" :key="index"
                 class="sl-box">
              <template v-if="boxIndex === 1 && this.getGroupsUserCount(this.filteredStudentAcademyUserGroups, boxIndex === 1) === 0">
                <div class="slb-et">
                  <span>왼쪽의 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="add_circle" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: rgb(192, 192, 192); margin-right: 4px;"><path fill="#00ABFF" d="M10 1.667C5.4 1.667 1.667 5.4 1.667 10S5.4 18.333 10 18.333 18.333 14.6 18.333 10 14.6 1.667 10 1.667zm4.167 9.166h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334V5.833h1.666v3.334h3.334v1.666z"></path></svg>를 눌러<br/>공유할 학생을 선택하세요</span>
                </div>
              </template>
              <template v-else>
                <template v-for="(groupItem, index) in this.filteredStudentAcademyUserGroups" :key="index">
                  <div v-if="this.getGroupUserCount(groupItem, boxIndex === 1) > 0"
                       class="slb-group">
                    <div v-if="(groupItem.groupName ?? '').length > 0"
                         :class="['bg-header-wrap', {'expand': this.isExpandStudentGroupHeader(groupItem.groupName)}]"
                         @click="() => this.onClickStudentGroupHeader(groupItem.groupName)">
                      <svg data-v-64ab11cc="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" data-svg-name="dropdown_arrow_up" class="is-animate degree-90 css-he2h7v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(192, 192, 192);"><path data-v-64ab11cc="" fill="#000" d="M16.586 15.5c.89 0 1.337-1.077.707-1.707l-4.586-4.586c-.39-.39-1.024-.39-1.414 0l-4.586 4.586c-.63.63-.184 1.707.707 1.707h9.172z"></path></svg>
                      <p>{{ groupItem.groupName }}</p>
                      <span>{{ `${utils.numberFormat(this.getGroupUserCount(groupItem, boxIndex === 1))}명` }}</span>
                      <div class="hw-tail-icon"
                           onclick="event.stopPropagation()"
                           @click="() => this.onClickStudentAcademyUserTailBtn(groupItem.academyUsers, boxIndex === 0)">
                        <svg v-if="boxIndex === 0" data-v-64ab11cc="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="add_circle" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(0, 171, 255);"><path data-v-64ab11cc="" fill="#00ABFF" d="M10 1.667C5.4 1.667 1.667 5.4 1.667 10S5.4 18.333 10 18.333 18.333 14.6 18.333 10 14.6 1.667 10 1.667zm4.167 9.166h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334V5.833h1.666v3.334h3.334v1.666z"></path></svg>
                        <svg v-else-if="boxIndex === 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" data-svg-name="remove_fill" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(51, 51, 51);"><path fill="silver" d="M8 1.333C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zm3.334 7.334H4.667V7.333h6.667v1.334z"></path></svg>
                      </div>
                    </div>
                    <template v-for="(academyUserItem, index) in groupItem.academyUsers" :key="index">
                      <template v-if="(boxIndex === 0 && academyUserItem.isSelected === false) || (boxIndex === 1 && academyUserItem.isSelected === true)">
                        <div :class="['bg-user-wrap', {'expand': this.isExpandStudentGroupHeader(groupItem.groupName ?? '')}]"
                             @click="() => this.onClickStudentAcademyUser(academyUserItem.academyUserId)">
                          <p>{{ academyUserItem.name }}</p>
                          <svg v-if="boxIndex === 0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="add_circle" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(0, 171, 255);"><path fill="#00ABFF" d="M10 1.667C5.4 1.667 1.667 5.4 1.667 10S5.4 18.333 10 18.333 18.333 14.6 18.333 10 14.6 1.667 10 1.667zm4.167 9.166h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334V5.833h1.666v3.334h3.334v1.666z"></path></svg>
                          <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" data-svg-name="remove_fill" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(51, 51, 51);"><path fill="silver" d="M8 1.333C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zm3.334 7.334H4.667V7.333h6.667v1.334z"></path></svg>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div class="bw-divider"></div>
          <h1>공유할 선생님 선택</h1>
          <div class="bw-search-wrap">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="search" class="react-time-search-icon css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(159, 159, 159);"><path fill="#9F9F9F" d="M12.917 11.667h-.659l-.233-.225c.817-.95 1.308-2.184 1.308-3.525 0-2.992-2.425-5.417-5.416-5.417C4.925 2.5 2.5 4.925 2.5 7.917c0 2.991 2.425 5.416 5.417 5.416 1.341 0 2.575-.491 3.525-1.308l.225.233v.659l4.166 4.158 1.242-1.242-4.158-4.166zm-5 0c-2.075 0-3.75-1.675-3.75-3.75s1.675-3.75 3.75-3.75 3.75 1.675 3.75 3.75-1.675 3.75-3.75 3.75z"></path></svg>
            <input type="text" placeholder="선생님 이름 검색" v-model="this.searchTeacherName"/>
            <button @click="() => this.searchTeacherName = null">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: rgb(192, 192, 192);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
            </button>
          </div>
          <div class="bw-side-list-wrap">
            <div v-for="(boxIndex, index) in [...Array(2).keys()]" :key="index"
                 class="sl-box">
              <template v-if="boxIndex === 1 && this.getGroupsUserCount(this.filteredTeacherAcademyUserGroups, boxIndex === 1) === 0">
                <div class="slb-et">
                  <span>왼쪽의 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="add_circle" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: rgb(192, 192, 192); margin-right: 4px;"><path fill="#00ABFF" d="M10 1.667C5.4 1.667 1.667 5.4 1.667 10S5.4 18.333 10 18.333 18.333 14.6 18.333 10 14.6 1.667 10 1.667zm4.167 9.166h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334V5.833h1.666v3.334h3.334v1.666z"></path></svg>를 눌러<br/>공유할 선생님을 선택하세요</span>
                </div>
              </template>
              <template v-else>
                <template v-for="(groupItem, index) in this.filteredTeacherAcademyUserGroups" :key="index">
                  <div class="slb-group">
                    <template v-for="(academyUserItem, index) in groupItem.academyUsers" :key="index">
                      <template v-if="(boxIndex === 0 && academyUserItem.isSelected === false) || (boxIndex === 1 && academyUserItem.isSelected === true)">
                        <div :class="['bg-user-wrap', {'expand': true}]"
                             @click="() => this.onClickTeacherAcademyUser(academyUserItem.academyUserId)">
                          <p>{{ academyUserItem.name }}</p>
                          <svg v-if="boxIndex === 0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="add_circle" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(0, 171, 255);"><path fill="#00ABFF" d="M10 1.667C5.4 1.667 1.667 5.4 1.667 10S5.4 18.333 10 18.333 18.333 14.6 18.333 10 14.6 1.667 10 1.667zm4.167 9.166h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334V5.833h1.666v3.334h3.334v1.666z"></path></svg>
                          <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" data-svg-name="remove_fill" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(51, 51, 51);"><path fill="silver" d="M8 1.333C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zm3.334 7.334H4.667V7.333h6.667v1.334z"></path></svg>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-list">
        <button @click="onSubmit">공유하기</button>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <LoadingDialog :is-show="this.isLoadingDialog"/>
  </Teleport>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import AcademyService from "@/services/academy.service";
import utils from "../utils";
import BoardService from "@/services/board.service";

export default {
  components: {LoadingDialog},
  props: {
    isVisible: {type: Boolean, default: false},
    onClose: Function,
    menuId: Number,
    menuTitle: String,
  },
  watch: {
    async isVisible() {
      if (this.isVisible) {
        await this.initData();
        this.$refs.bodyContents.scrollTop = 0;
      }
    },
  },
  data() {
    return {
      // Commons
      isLoadingDialog: false,
      utils,

      // Students
      searchStudentName: null,
      studentTabs: ['학년', '반'],
      selectStudentTabIndex: null,
      academyClasses: [],
      studentAcademyUsers: [],
      expandStudentYearGroupNames: [],
      expandStudentClassGroupNames: [],

      // Teachers
      searchTeacherName: null,
      teacherAcademyUsers: [],
    };
  },
  methods: {
    getGroupsUserCount(groups, isCheckSelect) {
      return groups.reduce((acc, cur) => {
        return acc + this.getGroupUserCount(cur, isCheckSelect);
      }, 0);
    },
    async onSubmit() {
      const menuId = this.menuId ?? null;
      if (menuId === null) {
        this.$toast.error('메뉴 ID를 확인할 수 없습니다');
        return;
      }

      const studentAcademyUserIds = this.studentAcademyUsers.filter(e => {
        return e.ie === true;
      }).map(e => {
        return e.au_id;
      });
      const teacherAcademyUserIds = this.teacherAcademyUsers.filter(e => {
        return e.ie === true;
      }).map(e => {
        return e.au_id;
      });

      this.isLoadingDialog = true;
      try {
        const res = await BoardService.menuShare(menuId, [
          ...studentAcademyUserIds,
          ...teacherAcademyUserIds,
        ]);
        if (res) {
          this.$toast.success('공유되었습니다')
          this.onClose();
        } else {
          this.$toast.error('공유에 실패하였습니다')
        }
        this.isLoadingDialog = false;
        return;
      } catch (e) {
        this.$toast.error('공유 중 오류가 발생하였습니다')
      }
      this.isLoadingDialog = false;
    },
    getAcademyUserGroupItem(academyUser) {
      return {
        'academyUserId': academyUser.au_id,
        'name': academyUser.nm,
        'isSelected': academyUser.ie,
      };
    },
    getGroupUserCount(groupItem, isCheckSelect) {
      return groupItem.academyUsers.filter(e => {
        return e.isSelected === isCheckSelect;
      }).length;
    },
    isExpandStudentGroupHeader(groupName) {
      let groupNames = [];
      if (this.selectStudentTabIndex === 0) {
        groupNames = this.expandStudentYearGroupNames;
      } else if (this.selectStudentTabIndex === 1) {
        groupNames = this.expandStudentClassGroupNames;
      } else {
        return true;
      }

      return groupNames.findIndex(e => e === groupName) >= 0;
    },
    onClickStudentGroupHeader(groupName) {
      let groupNames = [];
      if (this.selectStudentTabIndex === 0) {
        groupNames = this.expandStudentYearGroupNames;
      } else if (this.selectStudentTabIndex === 1) {
        groupNames = this.expandStudentClassGroupNames;
      } else {
        return;
      }

      const findIndex = groupNames.findIndex(e => e === groupName);
      if (findIndex >= 0) {
        groupNames.splice(findIndex, 1);
      } else {
        groupNames.push(groupName);
      }
    },
    onClickStudentAcademyUserTailBtn(academyUsers, isSelect) {
      const academyUserIds = academyUsers.map(e => e.academyUserId);
      this.academyUsers = this.studentAcademyUsers.map(e => {
        if (academyUserIds.includes(e.au_id)) {
          e.ie = isSelect;
        }
        return e;
      });
    },
    onClickStudentAcademyUser(academyUserId) {
      this.studentAcademyUsers = this.studentAcademyUsers.map(e => {
        if (e.au_id === academyUserId) {
          e.ie = !e.ie;
        }
        return e;
      });
    },
    onClickTeacherAcademyUser(academyUserId) {
      this.teacherAcademyUsers = this.teacherAcademyUsers.map(e => {
        if (e.au_id === academyUserId) {
          e.ie = !e.ie;
        }
        return e;
      });
    },
    onClickSelectStudentTab(index) {
      if (this.selectStudentTabIndex === index) {
        this.selectStudentTabIndex = null;
      } else {
        this.selectStudentTabIndex = index;
      }
    },
    async initData() {
      this.isLoadingDialog = true;
      this.searchStudentName = null;
      this.selectStudentTabIndex = null;
      this.academyClasses = [];
      this.studentAcademyUsers = [];
      this.teacherAcademyUsers = [];

      const res = await AcademyService.getTeacherLessonShareUsers(this.menuId);
      if (!res) {
        this.$toast.error('데이터를 불러오는 중 오류가 발생하였습니다');
        this.isLoadingDialog = false;
      }

      this.academyClasses = res.acs;
      this.studentAcademyUsers = res.sts;
      this.teacherAcademyUsers = res.tcs;

      this.isLoadingDialog = false;
    },
  },
  computed: {
    schoolYearGroups() {
      return this.studentAcademyUsers.reduce((acc, cur) => {
        const schoolType = cur.st;
        const schoolYear = cur.sy;
        if (acc.findIndex(e => {
          return e.schoolType == schoolType && e.schoolYear == schoolYear;
        }) < 0) {
          acc.push({
            'groupName': schoolType.length === 0 && schoolYear.length === 0 ? '선택없음' : `${schoolType}${schoolYear}`,
            'schoolType': schoolType,
            'schoolYear': schoolYear,
          });
        }
        return acc;
      }, []);
    },
    filteredTeacherAcademyUserGroups() {
      const searchKeyword = this.searchTeacherName ?? '';
      const academyUsers = this.teacherAcademyUsers.filter(e => {
        return e.nm.includes(searchKeyword);
      });
      return academyUsers.map(e => {
        return {'academyUsers': [this.getAcademyUserGroupItem(e)]};
      });
    },
    filteredStudentAcademyUserGroups() {
      const searchKeyword = this.searchStudentName ?? '';
      const academyUsers = this.studentAcademyUsers.filter(e => {
        return e.nm.includes(searchKeyword);
      });
      const selectTabIndex = this.selectStudentTabIndex;

      if (selectTabIndex === null) { // 선택 안 함
        return academyUsers.map(e => {
          return {
            'academyUsers': [this.getAcademyUserGroupItem(e)],
          };
        });
      } else if (selectTabIndex === 0) { // 학년
        return this.schoolYearGroups.map(e => {
          const schoolType = e.schoolType;
          const schoolYear = e.schoolYear;
          return {
            'groupName': e.groupName,
            'academyUsers': academyUsers.filter(e => {
              return e.st === schoolType && e.sy === schoolYear;
            }).map(e => {
              return this.getAcademyUserGroupItem(e);
            }),
          };
        });
      } else if (selectTabIndex === 1) { // 반
        return this.academyClasses.map(e => {
          const classId = e.id;
          return {
            'groupName': e.nm,
            'academyUsers': academyUsers.filter(e => {
              return e.ac_ids.includes(classId);
            }).map(e => {
              return this.getAcademyUserGroupItem(e);
            }),
          };
        });
      }
      return [];
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: calc(100% - 40px - 40px);
    min-width: 600px;
    height: 100%;
    max-height: calc(100% - 24px - 24px);
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      &>h1 {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.01em;
      }
      &>h2 {
        margin-left: 12px;
        color: rgb(112, 112, 112);
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.002em;
      }
      &>.close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        &>svg {
          width: 100%;
          color: rgb(112, 112, 112);
          &>path {
            fill: currentcolor;
          }
        }
      }
    }
    &>.body-contents {
      flex: 1;
      padding: 24px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: auto;
      &>h2 {
        width: 100%;
        font-weight: 600;
        font-size: 14px;
      }
      &>.bc-box-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        &>h1 {
          font-size: 16px;
          padding: 10px 0;
          font-weight: 700;
        }
        &>.bw-tab-list {
          width: 100%;
          display: flex;
          &>.tl-side-wrap {
            flex: 1;
            display: flex;
            &>.tls-item {
              flex: 1;
              cursor: pointer;
              background-color: rgb(250, 250, 250);
              padding: 7px 0;
              text-align: center;
              width: 100px;
              border-top: 1px solid rgb(232, 232, 232);
              border-left: 1px solid rgb(232, 232, 232);
              font-size: 14px;
              &:last-child {
                border-right: 1px solid rgb(232, 232, 232);
              }
              &.active {
                background-color: white;
              }
            }
          }
        }
        &>.bw-search-wrap {
          width: 100%;
          display: flex;
          padding: 7px;
          border-radius: 4px;
          align-items: center;
          border-top: 1px solid rgb(224, 224, 224);
          border-left: 1px solid rgb(224, 224, 224);
          border-right: 1px solid rgb(224, 224, 224);
          &>svg {
            width: 17px;
            height: 17px;
          }
          &>input {
            flex: 1;
            border: 0 none;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.002em;
            color: rgb(51, 51, 51);
            outline: none;
            &::placeholder {
              color: rgb(210,210,210);
            }
          }
        }
        &>.bw-side-list-wrap {
          width: 100%;
          display: flex;
          border: 1px solid rgb(232, 232, 232);
          &>.sl-box {
            flex: 1;
            display: flex;
            flex-direction: column;
            height: 250px;
            overflow-y: auto;
            &:not(:first-child) {
              border-left: 1px solid rgb(232, 232, 232);
            }
            &>.slb-et {
              display: flex;
              margin: auto;
              align-items: center;
              justify-content: center;
              &>span {
                font-weight: 400;
                font-size: 13px;
                letter-spacing: -0.002em;
                text-align: center;
                cursor: default;
              }
            }
            &>.slb-group {
              width: 100%;
              display: flex;
              flex-direction: column;
              &>.bg-header-wrap {
                width: 100%;
                display: flex;
                height: 48px;
                align-items: center;
                background-color: #fafafa;
                padding-left: 24px;
                cursor: pointer;
                gap: 7px;
                &>svg {
                  transition: all .2s ease-in-out;
                  transform: rotate(90deg);
                }
                &>p {
                  flex: 1;
                  color: #333;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                &>span {
                  padding-left: 10px;
                  color: rgb(51, 51, 51);
                  font-size: 14px;
                  white-space: nowrap;
                }
                &>.hw-tail-icon {
                  display: flex;
                  padding: 0 10px;
                  height: 100%;
                  align-items: center;
                  &>svg {
                    width: 15px;
                    height: 15px;
                  }
                }
                &.expand {
                  &>svg {
                    transform: rotate(180deg);
                  }
                }
              }
              &>.bg-user-wrap {
                width: 100%;
                display: flex;
                height: 0;
                align-items: center;
                padding-right: 10px;
                padding-left: 24px;
                background-color: white;
                border-bottom: 0 solid rgb(232, 232, 232);
                transition: all .2s ease-in-out;
                cursor: pointer;
                overflow: hidden;
                &.expand {
                  height: 48px;
                }
                &:hover {
                  background-color: rgb(245, 245, 245);
                }
                &>p {
                  flex: 1;
                  color: #333;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                &>svg {
                  width: 15px;
                  height: 15px;
                  transform: rotate(180deg);
                  margin-left: auto;
                }
              }
            }
          }
        }
        &>.bw-divider {
          width: 100%;
          height: 1px;
          margin: 16px 0;
        }
      }
    }
    &>.btn-list {
      border-top: 1px solid rgb(224, 224, 224);
      width: 100%;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      align-items: center;
      padding: 10px 24px;
      &>button {
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        width: 150px;
        height: 40px;
        border-radius: 4px;
        transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
        background-color: #008ED5;
        color: white;
        font-weight: 500;
        line-height: 1;
      }
    }
  }
}
</style>
