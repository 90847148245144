import api from "./api"
import {MACLItemVo} from "@/models/macl_item_vo";

class AcademyService {
  getAcademyNames() {
    return api.post('/academy/name/')
  }
  
  getMyAcademyUser() {
    return api.get('/academy/academy_user/')
  }
  
  getAcademy(academyInfoId) {
    return api.get(`/view_set/academy_info/${academyInfoId}/`)
  }
  
  getMyAcademy() {
    const params = {my_academy: true}
    return api.get(`/view_set/academy_info/`, { params })
  }
  
  getAcademyUsers(params) {
    return api.get(`/view_set/academy_user/`, { params })
  }

  getStudents(pageSize, page) {
    const params = {
      'page_size': pageSize,
      'page': page,
    };

    return api.get('/academy_info/students', { params })
  }

  getAcademyUser(academyUserId, params = {}) {
    return api.get(`/view_set/academy_user/${academyUserId}`, { params })
  }

  updateAcademyUser(academyUserId, payload) {
    return api.patch(`/view_set/academy_user/${academyUserId}/`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  createAcademyMemberRequest(payload) {
    return api.post('/view_set/academy_member_request/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
  
  getAcademyMemberRequests(params) {
    return api.get(`/view_set/academy_member_request/`, { params })
  }

  acceptMemberRequest(requestId) {
    return api.post('/academy/accept_member_request/', {
      request_id: requestId,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  rejectMemberRequest(requestId) {
    return api.post('/academy/reject_member_request/', {
      request_id: requestId,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
  
  getMyAcademyClasses(isIncludeStudentIds=false) {
    const params = {};
    if (isIncludeStudentIds) {
      params['is_include_student_ids'] = true;
    }
    return api.get(`/academy_info/my_academy_classes`, { params })
  }

  getMyAcademyUsers(isOnlyStudent=false) {
    const params = {};
    if (isOnlyStudent) {
      params['is_only_student'] = true;
    }
    return api.get(`/academy_info/my_academy_users`, { params });
  }

  getAcademyClass(academyClassId) {
    return api.get(`/academy_info/academy_class/${academyClassId}`);
  }

  createAcademyClass(payload) {
    return api.post('/view_set/academy_class/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  deleteAcademyClassIdList(idList=[]) {
    return api.post('/academy/delete_academy_class_id_list/', {
      id_list: idList,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  createAcademyUser(payload) {
    return api.post('/academy/create_academy_user/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  getAcademyReports(params) {
    return api.get(`/view_set/academy_report/`, { params })
  }

  getAcademyReport(reportId, params) {
    return api.get(`/view_set/academy_report/${reportId}/`, { params })
  }

  getAcademyReportInfo(reportId) {
    return api.get(`/academy/get_report_info/${reportId}/`)
  }

  deleteAcademyReport(reportId, payload) {
    return api.delete(`/view_set/academy_report/${reportId}`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  getReportLearnCategories(params) {
    return api.get(`/view_set/report_learn_category/`, { params })
  }

  getReportContentsCategories(params) {
    return api.get(`/view_set/report_contents_category/`, { params })
  }

  createAcademyReport(payload) {
    return api.post('/view_set/academy_report/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  updateAcademyReport(academyReportId, payload) {
    return api.patch(`/view_set/academy_report/${academyReportId}/`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
  
  getAcademyStudentUsers(academyInfoId) {
    return api.get(`/academy/get_academy_student_users/${academyInfoId}/`)
  }

  getLearningHistory(params) {
    return api.get(`/academy/learning_history/`, { params })
  }

  getReviewList() {
    try {
      return api.get('/academy_info/review');
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  changeAcademyUserClass(
      isInclude = false,
      academyUserId, // AcademyUser ID
      academyClassId, // AcademyClass ID
  ) {
    return api.post('/academy/change_academy_user_class/', {
      'is_include': isInclude,
      'academy_user_id': academyUserId,
      'academy_class_id': academyClassId,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  /**
   * @return {Promise<MACLItemVo[]|null>}
   */
  async getMyAcademyClassList() {
    try {
      const res = await api.get('/academy_info/my_academy_class_list');
      return res.map((e) => MACLItemVo.fromJson(e));
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherDashboardData(academyClassId) {
    try {
      return await api.get(`/academy_info/teacher_dashboard_data?academy_class_id=${academyClassId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherLessonUserList(academyClassId) {
    try {
      return await api.get(`/academy_info/teacher_lesson_user_list?academy_class_id=${academyClassId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherDashboardAiMessage(academyClassId) {
    try {
      return await api.get(`/academy_info/teacher/dashboard/ai_message?academy_class_id=${academyClassId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherDashboardEvalReportDiagEval(academyClassId) {
    try {
      return await api.get(`/academy_info/teacher/dashboard/eval_report/diag_eval?academy_class_id=${academyClassId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherDashboardEvalReportUnitEval(academyClassId) {
    try {
      return await api.get(`/academy_info/teacher/dashboard/eval_report/unit_eval?academy_class_id=${academyClassId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherDashboardAnalysis(academyClassId) {
    try {
      return await api.get(`/academy_info/teacher/dashboard/analysis?academy_class_id=${academyClassId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherDashboardStudentDistribution(academyClassId) {
    try {
      return await api.get(`/academy_info/teacher/dashboard/student_distribution?academy_class_id=${academyClassId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherDashboardStudentList(academyClassId) {
    try {
      return await api.get(`/academy_info/teacher/dashboard/student_list?academy_class_id=${academyClassId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherDashboardLearningStatus(academyClassId, tabName, startDate, endDate) {
    try {
      return await api.get(`/academy_info/teacher/dashboard/learning_status?academy_class_id=${academyClassId}&tab_name=${tabName}&start_date=${startDate}&end_date=${endDate}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherLessonShareUsers(menuId) {
    try {
      return await api.get(`/academy_info/teacher/lesson/share_users?menu_id=${menuId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherSelfLearningResultUsers(academyClassId) {
    try {
      return await api.get(`/academy_info/teacher/self_learning_result/users?academy_class_id=${academyClassId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherSelfLearningResultDiagEvalList(academyUserId) {
    try {
      return await api.get(`/academy_info/teacher/self_learning_result/diag_eval_list?academy_user_id=${academyUserId}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherSelfLearningResultBodyData(academyUserId, evalType, id) {
    try {
      return await api.get(`/academy_info/teacher/self_learning_result/body_data?academy_user_id=${academyUserId}&eval_type=${evalType}&target_id=${id}`);
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async getTeacherDashboardAnalysisDetail(params) {
    try {
      return await api.get(`/academy_info/teacher/dashboard/analysis/detail`, { params });
    } catch (e) {
      console.log(e);
    }
    return null;
  }
}

export default new AcademyService()
