<template>
  <LessonBoard
    :user-items="this.userItems"
  />
</template>

<script>
import LessonBoard from "@/components/LessonBoard.vue";
import {mapGetters} from "vuex";
import AcademyService from "@/services/academy.service";

export default {
  components: {
    LessonBoard,
  },
  data() {
    return {
      userItems: [],
    };
  },
  mounted() {
    this.initUserItems();
  },
  methods: {
    async initUserItems() {
      const academyUserId = this.getUser?.academy_user?.id;
      if (academyUserId === null) return;

      const selectedAcademyClassId = this.getSelectedAcademyClassId;
      if (selectedAcademyClassId === null) return;

      const res = await AcademyService.getTeacherLessonUserList(selectedAcademyClassId);
      if (res === null) return;

      this.userItems = (res.student_items ?? []).map((e) => {
        return {
          name: e.name,
          isOnline: e.is_online,
        };
      });
    },
  },
  computed: {
    ...mapGetters('auth', [
      'getUser',
    ]),
    ...mapGetters('classStore', [
      'getSelectedAcademyClassId',
    ]),
  },
}
</script>
