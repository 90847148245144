<template>
  <LessonBoard :user-items="[]"/>
</template>

<script>
import LessonBoard from "@/components/LessonBoard.vue";

export default {
  components: {
    LessonBoard,
  },
}
</script>
