<template>
  <div class="section-5-wrap">
    <div class="v2-base-container">
      <div class="s-title-wrap">
        <TitleTooltip
            title="학생 리스트"
            tooltip="각 학생의 문제풀이, 정답률, 동영상 학습시간을 알 수 있습니다."
        />
        <template v-if="this.errorMsg !== null">
          <ErrorMsgBox :contents="this.errorMsg"/>
        </template>
        <template v-else-if="this.isLoading">
          <LoadingBox size="25px" style="margin: 10px auto"/>
        </template>
        <template v-else>
          <div class="s-order-list">
            <template v-for="(item, index) in this.studentListOrderTypes" :key="index">
              <div :class="['ol-item', {'active': this.selectStudentListOrderType == index}]"
                   @click="() => this.onClickStudentListOrderType(index)">
                <template v-if="this.selectStudentListOrderType == index">
                  <img v-if="this.selectStudentListOrderSubType == 0" src="@/assets/images/order-top.png" alt="">
                  <img v-else src="@/assets/images/order-bottom.png" alt="">
                </template>
                <template v-else>
                  <img src="@/assets/images/order.png" alt="">
                </template>
                <span>{{ item }}</span>
              </div>
            </template>
          </div>
        </template>
      </div>
      <template v-if="this.errorMsg !== null">
        <ErrorMsgBox :contents="this.errorMsg"/>
      </template>
      <template v-else-if="this.isLoading">
        <LoadingBox size="25px" style="margin: 10px auto"/>
      </template>
      <template v-else>
        <div class="s-body-wrap">
          <div v-for="(studentListItem, index) in this.sortedStudentListItems" :key="index"
               class="sb-item"
               @click="() => this.onClickStudentListItem(studentListItem)">
            <div class="i-thumb-box">
              <img :src="studentListItem.url" alt="">
            </div>
            <div class="i-body">
              <div class="ib-title">
                <p>{{ studentListItem.nm }}</p>
                <img src="@/assets/images/arrow-right-4.png" alt="">
              </div>
              <div class="ib-contents">
                <div class="c-item">
                  <p>문제 풀이</p>
                  <span>{{ studentListItem.asr_prb_cnt }}개</span>
                </div>
                <div class="c-item">
                  <p>정답률</p>
                  <span>{{ studentListItem.answer_rate }}%</span>
                </div>
                <div class="c-item">
                  <p>학습 시간</p>
                  <span>{{ studentListItem.sec }}분</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>

  <Teleport to="body">
    <StudentProfilePopup
        :is-visible="this.studentProfilePopupItem !== null"
        :on-close="() => this.studentProfilePopupItem = null"
        :student-item="this.studentProfilePopupItem"
    />
  </Teleport>
</template>

<script>
import TitleTooltip from "@/components/TitleTooltip.vue";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import AcademyService from "@/services/academy.service";
import StudentProfilePopup from "@/components/StudentProfilePopup.vue";
import AIAssistantPopup from "@/components/AIAssistantPopup.vue";

export default {
  components: {
    AIAssistantPopup, StudentProfilePopup,
    LoadingBox, ErrorMsgBox,
    TitleTooltip,
  },
  props: {
    academyClassId: Number,
  },
  watch: {
    academyClassId() {
      if (this.academyClassId !== null) {
        this.initData();
      }
    },
  },
  data() {
    return {
      errorMsg: null,
      isLoading: true,
      studentListOrderTypes: ['이름 순', '정답률', '문제풀이 순'],
      selectStudentListOrderType: null,
      selectStudentListOrderSubType: 0, // 0: 오름차순, 1: 내림차순
      studentListItems: [],
      studentProfilePopupItem: null,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    onClickStudentListItem(studentListItem) {
      this.studentProfilePopupItem = {
        'thumbnailUrl': studentListItem.url,
        'name': studentListItem.nm,
        'email': studentListItem.email,
        'schoolYear': studentListItem.sy,
        'phone': studentListItem.pn,
      }
    },
    async initData() {
      if (this.academyClassId === null) { this.errorMsg = '반을 먼저 선택해주세요'; return; }

      this.isLoading = true;

      let isError = false;
      await Promise.all([
        AcademyService.getTeacherDashboardStudentList(this.academyClassId).then(res => {
          if (res) {
            this.studentListItems = res.result_list;
          } else isError = true;
        }),
      ]);

      if (isError) {
        this.errorMsg = '데이터를 불러오는 중 오류가 발생하였습니다';
      }
      this.isLoading = false;
    },
    onClickStudentListOrderType(type) {
      const beforeType = this.selectStudentListOrderType;
      if (type == beforeType) {
        if (this.selectStudentListOrderSubType == 0) {
          this.selectStudentListOrderSubType = 1;
        } else {
          this.selectStudentListOrderType = null;
        }
      } else {
        this.selectStudentListOrderType = type;
        this.selectStudentListOrderSubType = 0;
      }
    },
  },
  computed: {
    sortedStudentListItems() {
      const studentListItems = [...this.studentListItems];

      const orderType = this.selectStudentListOrderType;
      if (orderType != null) {
        const subOrderType = this.selectStudentListOrderSubType;
        studentListItems.sort(function (a, b) {
          if (orderType == 0) { // 이름 순
            if (subOrderType == 0) { // 오름차순
              return a.nm > b.nm ? 1 : -1;
            } else { // 내림차순
              return a.nm > b.nm ? -1 : 1;
            }
          } else if (orderType == 1) { // 정답률
            if (subOrderType == 0) { // 오름차순
              return a.answer_rate > b.answer_rate ? 1 : -1;
            } else { // 내림차순
              return a.answer_rate > b.answer_rate ? -1 : 1;
            }
          } else if (orderType == 2) { // 문제풀이 순
            if (subOrderType == 0) { // 오름차순
              return a.asr_prb_cnt > b.asr_prb_cnt ? 1 : -1;
            } else { // 내림차순
              return a.asr_prb_cnt > b.asr_prb_cnt ? -1 : 1;
            }
          }
          return 0;
        });
      }

      return studentListItems;
    }
  },
}
</script>

<style scoped lang="scss">

</style>
