<template>
  <div class="sec-left-wrap">
    <div class="sec-tab-wrap">
      <p>범위 선택</p>
      <div class="st-divider"></div>
      <div class="select-wrap">
        <select v-model="this.selectRange" @change="this.onChangeRange">
          <option value="null" hidden>선택</option>
          <option v-for="(name, index) in this.rangeOptions" :key="index" :value="name">{{ name }}</option>
        </select>
        <template v-if="this.selectRange !== null">
          <img src="@/assets/images/arrow-right-7.png" alt="">
          <select v-model="this.selectSubRange" @change="this.onChangeSubRange">
            <option value="null" hidden>선택</option>
            <option v-for="(name, index) in this.rangeSubOptions" :key="index" :value="name">{{ name }}</option>
          </select>
        </template>
      </div>
    </div>
    <div class="sec-list-wrap">
      <V2TreeList
        :nodes="this.treeNodes"
        :on-node-checked="this.onNodeChanged"
        :on-node-unchecked="this.onNodeChanged"
      />
    </div>
  </div>
</template>

<script>
import V2TreeList from "@/components/V2TreeList.vue";
import Models from "@/models";
import SelfStudyService from "@/services/selfStudy.service";
import models from "@/models";

export default {
  components: {V2TreeList},
  props: {
    onChangeSelectRange: Function,
    onChangeSelectSubRange: Function,
    onChangeSelectDiagPtnIds: Function,
    onChangeSelectDiagPtnPreCnt: Function,
    onChangeSelectUnitPrbCnts: Function,
  },
  watch: {
    selectRange(data) {
      this.onChangeSelectRange(data);
    },
    selectSubRange(data) {
      this.onChangeSelectSubRange(data);
    },
  },
  data() {
    return {
      rangeOptions: Models.schoolTypeList(),
      selectRange: null,
      selectSubRange: null,

      treeNodes: {},
    };
  },
  methods: {
    onChangeRange() {
      this.selectSubRange = null;
    },
    async onChangeSubRange() {
      const selectRange = this.selectRange;
      const selectSubRange = this.selectSubRange;
      if (selectRange == null || selectSubRange == null) {
        this.$toast.error('범위를 모두 선택해주세요');
        return;
      }
      this.treeNodes = {};
      this.onChangeSelectDiagPtnIds([]);
      this.onChangeSelectDiagPtnPreCnt(0);
      this.onChangeSelectUnitPrbCnts([]);

      const schoolType = selectRange;
      const params = {
        'school_type': schoolType,
        'is_node_list': true,
      };
      if (schoolType == '고등학교') {
        params['problem_subject'] = selectSubRange;
      } else {
        params['school_year'] = selectSubRange.split('-')[0];
        params['term'] = selectSubRange.split('-')[1];
      }

      let res;
      try {
        res = await SelfStudyService.getSelfLearningModeList(params);
      } catch (e) {
        this.$toast.error('유형 리스트를 불러오는 중 오류가 발생하였습니다');
        console.error(e);
        return;
      }

      this.treeNodes = res;
      this.onChangeSelectDiagPtnIds([]);
      this.onChangeSelectDiagPtnPreCnt(0);
      this.onChangeSelectUnitPrbCnts([]);
    },
    async onNodeChanged() {
      const treeNodes = this.treeNodes;

      const selectPtnIds = [];
      let diagPtnPreCnt = 0;
      let unitPrbCnts = models.problemTypeList(false).map(e => 0);
      Object.entries(treeNodes).forEach(entries => {
        const value = entries[1];
        if (value.depth == 3 && value.state?.checked) {
          selectPtnIds.push(value.pattern_id);
          diagPtnPreCnt += value.pre_ptn_cnt ?? 0;
          Object.entries(value.prb_cnts ?? []).forEach(entries => {
            unitPrbCnts[entries[0]] += entries[1];
          });
        }
      });

      this.onChangeSelectDiagPtnIds(selectPtnIds);
      this.onChangeSelectDiagPtnPreCnt(diagPtnPreCnt);
      this.onChangeSelectUnitPrbCnts(unitPrbCnts);
    },
  },
  computed: {
    rangeSubOptions() {
      const selectRange = this.selectRange;

      switch (selectRange) {
        case null: return [];
        case '초등학교': return ['6-1', '6-2'];
        case '고등학교': return Models.subjectList();
        default: return [
          '1-1', '1-2',
          '2-1', '2-2',
          '3-1', '3-2',
        ];
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
