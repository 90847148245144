<template>
  <div class="self-learning-mode-wrap">
    <V2Wrapper>
      <div class="slmw-inner">
        <div class="v2-base-container">
          <template v-if="this.errorMsg !== null">
            <ErrorMsgBox :contents="this.errorMsg"/>
          </template>
          <template v-else>
            <div class="wi-body-wrap">
              <SelfLearningModeTreeList
                :on-change-select-range="(e) => this.selectRange = e"
                :on-change-select-sub-range="(e) => this.selectSubRange = e"
                :on-change-select-diag-ptn-ids="(e) => this.selectDiagPtnIds = e"
                :on-change-select-diag-ptn-pre-cnt="(e) => this.selectDiagPtnPreCnt = e"
                :on-change-select-unit-prb-cnts="(e) => this.selectUnitPrbCnts = e"
              />
              <div class="sec-right-wrap">
                <div class="sec-tab-list">
                  <template v-for="(tabName, index) in this.tabs" :key="index">
                    <div :class="['tl-item', {'active': index === this.selectTabIndex}]"
                         @click="() => this.selectTabIndex = index"
                    >
                      <p>{{ tabName }}</p>
                      <div></div>
                    </div>
                  </template>
                </div>
                <div class="sec-body-wrap">
                  <template v-for="(unitItem, midIndex) in this.settingItems" :key="midIndex">
                    <div class="sb-item">
                      <div class="sbi-title">{{ unitItem.title }}</div>
                      <div class="sbi-list-wrap">
                        <template v-for="(item, subIndex) in unitItem.items" :key="subIndex">
                          <button :class="['il-item', {'active': item.isSelect}]"
                                  @click="() => this.onChangeSettingItem(midIndex, subIndex, !item.isSelect)"
                          >
                            <span>{{ item.name }}</span>
                          </button>
                        </template>
                      </div>
                    </div>
                  </template>
                  <template v-if="this.selectTabIndex === 0">
                    <div class="sb-item">
                      <div class="sbi-title">문항 수</div>
                      <div class="sbi-prb-eval-cnt-wrap">
                        <p>전학년 소단원 개수</p>
                        <p>x</p>
                        <div class="pc-sel-wrap">
                          <select v-model="this.selectDiagCnt">
                            <option value="null" hidden>선택</option>
                            <option v-for="(name, index) in [1, 2, 3]" :key="index" :value="name">{{ name }}</option>
                          </select>
                          <span>배수</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="this.selectTabIndex === 1">
                    <div class="sb-item">
                      <div class="sbi-title">문항 수&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ this.selectUnitPrbMaxCnt }}</span></div>
                      <div class="sbi-btn-list-wrap">
                        <button v-for="(prbCnt, index) in [25, 50]" :key="index"
                                :class="['lw-item', {'active': this.selectUnitPrbMaxCnt == prbCnt}]"
                                @click="() => this.selectUnitPrbMaxCnt = prbCnt">
                          <span>{{ prbCnt }}</span>
                        </button>
                      </div>
                      <div class="sbi-pc-slider-wrap">
                        <button @click="() => this.onChangeUnitPrbCntPrv()">
                          <span>- 5</span>
                        </button>
                        <div>
                          <slider
                            :modelValue="this.selectUnitPrbMaxCnt"
                            :width="'100%'"
                            :height="6"
                            :handleScale="2.5"
                            :min="0"
                            :max="50"
                            :step="1"
                            :alwaysShowHandle="true"
                            color="#F9D286"
                            trackColor="#fcf9f0"
                            :tooltip="true"
                            :tooltipColor="'#000000'"
                            :sticky="true"
                            :flip="false"
                            @change="(value) => this.selectUnitPrbMaxCnt = value"
                          />
                          <div>
                            <span>0</span>
                            <span>50</span>
                          </div>
                        </div>
                        <button @click="() => this.onChangeUnitPrbCntNext()">
                          <span>+ 5</span>
                        </button>
                      </div>
                    </div>
                    <div class="sb-item">
                      <div class="sbi-title">문제 타입</div>
                      <div class="sbi-btn-list-wrap">
                        <button v-for="(unitPrbType, index) in this.unitPrbTypes" :key="index"
                                :class="['lw-item', {'active': this.selectUnitPrbTypeIndex == index}]"
                                @click="() => this.selectUnitPrbTypeIndex = index">
                          <span>{{ unitPrbType }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="sb-item">
                      <div class="sbi-prb-eval-cnt-wrap">
                        <div class="sbi-title">심화문제</div>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <div class="pc-sel-wrap">
                          <select v-model="this.selectDiffPrbCnt">
                            <option value="null" hidden>선택</option>
                            <option v-for="(name, index) in [...Array(10).keys().map(i => i + 1)]" :key="index" :value="name">{{ name }}</option>
                          </select>
                          <span>문항</span>
                        </div>
                      </div>
                    </div>
                    <div class="sb-item">
                      <div class="sbi-prb-eval-cnt-wrap">
                        <div class="sbi-title">취약문제</div>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <div class="pc-sel-wrap">
                          <select v-model="this.selectWeakPrbCnt">
                            <option value="null" hidden>선택</option>
                            <option v-for="(name, index) in [...Array(10).keys().map(i => i + 1)]" :key="index" :value="name">{{ name }}</option>
                          </select>
                          <span>문항</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="sec-total-info-wrap">
                  <template v-if="this.selectTabIndex === 0">
                    <div class="tiw-item">
                      <p>문제 수</p>
                      <span>{{ this.diagTotalPrbCnt }}개</span>
                    </div>
                  </template>
                  <template v-else-if="this.selectTabIndex === 1">
                    <div class="tiw-item">
                      <p>문제 수</p>
                      <span>{{ this.unitTotalPrbCnt }}개</span>
                    </div>
                    <div class="tiw-item">
                      <p>적용된 유형수</p>
                      <span>{{ this.unitTotalPrbPtnCnt }}개</span>
                    </div>
                  </template>
                </div>
                <div class="sec-button-wrap" @click="() => this.onSubmit()">
                  <button>
                    <span>작성 완료</span>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <LoadingDialog
      :is-show="this.isShowLoadingDialog"
    />
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import V2TreeList from "@/components/V2TreeList.vue";
import {mapActions, mapGetters} from "vuex";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import utils from "@/utils";
import SelfStudyService from "@/services/selfStudy.service";
import LoadingDialog from "@/components/LoadingDialog.vue";
import SelfLearningModeTreeList from "@/components/SelfLearningModeTreeList.vue";
import models from "@/models";

export default {
  name: 'TeacherSelfLearningMode',
  components: {SelfLearningModeTreeList, LoadingDialog, ErrorMsgBox, V2TreeList, V2Wrapper},
  data() {
    return {
      errorMsg: null,
      isShowLoadingDialog: false,

      selectRange: null,
      selectSubRange: null,

      tabs: [
        '진단평가',
        '단원평가',
      ],
      selectTabIndex: 0,

      selectDiagCnt: 3,
      selectDiagPtnIds: [],
      selectDiagPtnPreCnt: 0,

      selectUnitPrbMaxCnt: 25,
      unitPrbTypes: models.problemTypeList(),
      selectUnitPrbTypeIndex: 0,
      selectDiffPrbCnt: 3,
      selectWeakPrbCnt: 3,
      selectUnitPrbCnts: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    onChangeUnitPrbCntPrv() {
      let nextValue = this.selectUnitPrbMaxCnt - 5;
      this.selectUnitPrbMaxCnt = nextValue > 0 ? nextValue : 0;
    },
    onChangeUnitPrbCntNext() {
      let nextValue = this.selectUnitPrbMaxCnt + 5;
      this.selectUnitPrbMaxCnt = nextValue > 50 ? 50 : nextValue;
    },
    async initData() {
      const academyUserId = this.getUser?.academy_user?.id;
      if (academyUserId === null) {
        this.errorMsg = '학원 ID를 알 수 없습니다';
        return;
      }

      const selectedAcademyClassId = this.getSelectedAcademyClassId;
      if (selectedAcademyClassId === null) {
        this.errorMsg = '반을 먼저 선택해주세요';
      }
    },
    onChangeSettingItem(midIndex, subIndex, isSelect) {
      this.changeSettingItemSelect({
        mainIndex: this.selectTabIndex,
        midIndex: midIndex,
        subIndex: subIndex,
        isSelect: isSelect,
      });
    },
    async onSubmit() {
      if (this.selectTabIndex === 0) {
        await this.onSubmitDiag();
      } else {
        await this.onSubmitUnit();
      }
    },
    async onSubmitDiag() {
      const academyClassId = this.getSelectedAcademyClassId;
      if (academyClassId === null) {
        this.$toast.error('반을 먼저 선택해주세요');
        return;
      }

      const selectRange = this.selectRange;
      const selectSubRange = this.selectSubRange;
      if (selectRange == null || selectSubRange == null) {
        this.$toast.error('범위를 모두 선택해주세요');
        return;
      }

      const selectDiagPtnIds = this.selectDiagPtnIds;
      if (selectDiagPtnIds.length == 0) {
        this.$toast.error('최소 한 개 이상 소단원을 선택해주세요');
        return;
      }

      const selectDiagPtnPreCnt = this.selectDiagPtnPreCnt;
      if (selectDiagPtnPreCnt === 0) {
        this.$toast.error('선택한 소단원에 연결된 이전 소단원이 없습니다');
        return;
      }

      const selectDiagCnt = this.selectDiagCnt;
      const settingDiag = this.getSettingDiag;

      this.isShowLoadingDialog = true;
      const res = await SelfStudyService.createTeacherDiagEval({
        'academy_class_id': academyClassId,
        'ptn_ids': selectDiagPtnIds.join('_'), // 선택한 소단원 유형 ID 리스트
        'is_f_cv': settingDiag[0].items[0].isSelect, // 빠른 학습자 - 개념영상
        'is_f_ip': settingDiag[0].items[1].isSelect, // 빠른 학습자 - 심화문제
        'is_f_vc': settingDiag[0].items[2].isSelect, // 빠른 학습자 - 취약개념
        'is_f_vp': settingDiag[0].items[3].isSelect, // 빠른 학습자 - 취약문제
        'is_f_ec': settingDiag[0].items[4].isSelect, // 빠른 학습자 - 실험콘텐츠
        'is_f_rc': settingDiag[0].items[5].isSelect, // 빠른 학습자 - 실감콘텐츠
        'is_n_cv': settingDiag[1].items[0].isSelect, // 일반 학습자 - 개념영상
        'is_n_ip': settingDiag[1].items[1].isSelect, // 일반 학습자 - 심화문제
        'is_n_vc': settingDiag[1].items[2].isSelect, // 일반 학습자 - 취약개념
        'is_n_vp': settingDiag[1].items[3].isSelect, // 일반 학습자 - 취약문제
        'is_n_ec': settingDiag[1].items[4].isSelect, // 일반 학습자 - 실험콘텐츠
        'is_n_rc': settingDiag[1].items[5].isSelect, // 일반 학습자 - 실감콘텐츠
        'is_s_cv': settingDiag[2].items[0].isSelect, // 느린 학습자 - 개념영상
        'is_s_ip': settingDiag[2].items[1].isSelect, // 느린 학습자 - 심화문제
        'is_s_vc': settingDiag[2].items[2].isSelect, // 느린 학습자 - 취약개념
        'is_s_vp': settingDiag[2].items[3].isSelect, // 느린 학습자 - 취약문제
        'is_s_ec': settingDiag[2].items[4].isSelect, // 느린 학습자 - 실험콘텐츠
        'is_s_rc': settingDiag[2].items[5].isSelect, // 느린 학습자 - 실감콘텐츠
        'prb_mul': selectDiagCnt,
      });
      this.isShowLoadingDialog = false;

      if (res) {
        this.$toast.success('작성 완료되었습니다');
        this.$router.push({ name: 'TeacherDashboard' });
      } else {
        this.$toast.error('작성 중 오류가 발생하였습니다');
      }
    },
    async onSubmitUnit() {
      const academyClassId = this.getSelectedAcademyClassId;
      if (academyClassId === null) { this.$toast.error('반을 먼저 선택해주세요'); return; }

      const selectRange = this.selectRange;
      const selectSubRange = this.selectSubRange;
      if (selectRange == null || selectSubRange == null) { this.$toast.error('범위를 모두 선택해주세요'); return; }

      const selectDiagPtnIds = this.selectDiagPtnIds;
      if (selectDiagPtnIds.length == 0) { this.$toast.error('최소 한 개 이상 소단원을 선택해주세요'); return; }

      const prbCnt = this.unitTotalPrbCnt;
      if (prbCnt <= 0) { this.$toast.error('문항 수가 1개 이상 되도록 선택해주세요'); return; }

      const settingDiag = this.getSettingDiag;
      const prbType = this.selectUnitPrbTypeIndex === 0 ? null : this.unitPrbTypes[this.selectUnitPrbTypeIndex];
      const diffPrbCnt = this.selectDiffPrbCnt;
      const weakPrbCnt = this.selectWeakPrbCnt;

      this.isShowLoadingDialog = true;
      const res = await SelfStudyService.createTeacherUnitEval({
        'academy_class_id': academyClassId,
        'ptn_ids': selectDiagPtnIds.join('_'), // 선택한 소단원 유형 ID 리스트
        'is_f_cv': settingDiag[0].items[0].isSelect, // 빠른 학습자 - 개념영상
        'is_f_ip': settingDiag[0].items[1].isSelect, // 빠른 학습자 - 심화문제
        'is_f_vc': settingDiag[0].items[2].isSelect, // 빠른 학습자 - 취약개념
        'is_f_vp': settingDiag[0].items[3].isSelect, // 빠른 학습자 - 취약문제
        'is_f_ec': settingDiag[0].items[4].isSelect, // 빠른 학습자 - 실험콘텐츠
        'is_f_rc': settingDiag[0].items[5].isSelect, // 빠른 학습자 - 실감콘텐츠
        'is_n_cv': settingDiag[1].items[0].isSelect, // 일반 학습자 - 개념영상
        'is_n_ip': settingDiag[1].items[1].isSelect, // 일반 학습자 - 심화문제
        'is_n_vc': settingDiag[1].items[2].isSelect, // 일반 학습자 - 취약개념
        'is_n_vp': settingDiag[1].items[3].isSelect, // 일반 학습자 - 취약문제
        'is_n_ec': settingDiag[1].items[4].isSelect, // 일반 학습자 - 실험콘텐츠
        'is_n_rc': settingDiag[1].items[5].isSelect, // 일반 학습자 - 실감콘텐츠
        'is_s_cv': settingDiag[2].items[0].isSelect, // 느린 학습자 - 개념영상
        'is_s_ip': settingDiag[2].items[1].isSelect, // 느린 학습자 - 심화문제
        'is_s_vc': settingDiag[2].items[2].isSelect, // 느린 학습자 - 취약개념
        'is_s_vp': settingDiag[2].items[3].isSelect, // 느린 학습자 - 취약문제
        'is_s_ec': settingDiag[2].items[4].isSelect, // 느린 학습자 - 실험콘텐츠
        'is_s_rc': settingDiag[2].items[5].isSelect, // 느린 학습자 - 실감콘텐츠
        'prb_cnt': prbCnt, // 문항 수
        'prb_type': prbType, // 문제 타입
        'diff_prb_cnt': diffPrbCnt, // 심화 문항 수
        'weak_prb_cnt': weakPrbCnt, // 취약 문항 수
      });
      this.isShowLoadingDialog = false;

      if (res) {
        this.$toast.success('작성 완료되었습니다');
        this.$router.push({ name: 'TeacherDashboard' });
      }
    },
    ...mapActions('settings', [
      'changeSettingItemSelect',
    ]),
  },
  computed: {
    diagTotalPrbCnt() {
      return utils.numberFormat(this.selectDiagPtnPreCnt * this.selectDiagCnt);
    },
    settingItems() {
      return this.selectTabIndex === 0 ? this.getSettingDiag : this.getSettingUnit;
    },
    unitTotalPrbCnt() {
      const selectUnitPrbCnts = this.selectUnitPrbCnts ?? [];

      // 문제 타입 필터링
      // 전체 필터링 여부 확인
      const typeIndex = this.selectUnitPrbTypeIndex;
      let prbCnt = 0;
      if (typeIndex === 0) {
        prbCnt = selectUnitPrbCnts.reduce((acc, cur) => {
          return acc + cur;
        }, 0);
      } else {
        prbCnt = selectUnitPrbCnts[typeIndex - 1]; // 전체 항목 제외한 인덱스
      }

      // 문항 수 필터링
      const maxPrbCnt = this.selectUnitPrbMaxCnt;
      if (prbCnt > maxPrbCnt) {
        prbCnt = maxPrbCnt;
      }

      return utils.numberFormat(prbCnt ?? 0);
    },
    unitTotalPrbPtnCnt() {
      return utils.numberFormat(this.selectDiagPtnIds.length);
    },
    ...mapGetters('settings', [
      'getSettingDiag',
      'getSettingUnit',
    ]),
    ...mapGetters('auth', [
      'getUser',
    ]),
    ...mapGetters('classStore', [
      'getSelectedAcademyClassId',
    ]),
  },
}
</script>
