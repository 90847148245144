<template>
  <div class="drag-list-wrap">
    <div class="list-array-wrap grid-single">
      <div class="list-wrap">
        <h1>{{ this.title }}</h1>
        <div class="list-header-wrap">
          <div class="list-tr">
            <div v-for="(head, index) in this.heads" :key="index"
              :class="['list-th', head.addClass]">
              {{ head.title }}
            </div>
          </div>
        </div>
        <div class="list-body-wrap">
          <draggable
            class="vue-draggable-next"
            tag="ul"
            :list="this.trList"
            v-bind="this.dragOptions"
            @start="() => this.onStartDragging()"
            @end="() => this.onEndDragging()"
            @change="this.onChangeDragging">
            <transition-group type="transition" name="flip-list">
              <li v-for="(trItem, trIndex) in this.trList"
                class="list-group-item"
                :key="trItem[0].order">
                <ListArrayBodyTr
                  :tdList="trItem"
                  :hasCPointer="this.hasCPointer(trItem)"
                  :tdAddClassList="this.heads.map(head => head.addClass)"
                  :tdTypeList="this.heads.map(head => head.type)"
                  :firstNumber="trIndex + 1"
                />
              </li>
            </transition-group>
          </draggable>
        </div>
      </div>
      <div class="list-wrap">
        <!-- Pagination -->
      </div>
    </div>
  </div>
</template>

<script>
import ListArrayBodyTr from "@/components/ListArrayBodyTr.vue";
import { VueDraggableNext } from "vue-draggable-next";

export default {
  name: 'EditStep2DetailPrbTab',
  components: {
    draggable: VueDraggableNext,
    ListArrayBodyTr,
  },
  props: {
    title: String,
    heads: Array,
    trList: Array,
    onStartDragging: Function,
    onEndDragging: Function,
    onChangeDragging: Function,
  },
  watch: {
  },
  mounted() {
  },
  data() {
    return {};
  },
  methods: {
    hasCPointer(tr) {
      const findIndex = Object.values(tr).findIndex(item => item.onClick);
      return findIndex > -1;
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.drag-list-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  .list-wrap {
    margin-top: 0;
  }
}
</style>
