<template>
  <div class="sl-tab-wrap">
    <template v-for="(tab, index) in this.tabs" :key="index">
      <div :class="['t-item', {'active': this.selectTabIndex === index}]"
           @click="() => this.onChangeSelectTabIndex(index)">
        <p>{{ tab }}</p>
        <div></div>
      </div>
    </template>
  </div>
  <div class="sl-list-wrap">
    <template v-for="(tabItem, index) in this.tabItems" :key="index">
      <div :class="['l-item', {'active': tabItem.isActive ?? false}]"
           @click="() => this.onClickTabItemIndex(index)">
        <p v-if="(tabItem.title ?? '').length > 0">{{ tabItem.title ?? '' }}</p>
        <span v-if="(tabItem.subTitle ?? '').length > 0">{{ tabItem.subTitle ?? '' }}</span>
        <div></div>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingBox from "@/components/LoadingBox.vue";

export default {
  components: {LoadingBox},
  props: {
    tabs: Array,
    selectTabIndex: Number,
    onChangeSelectTabIndex: Function,
    tabItems: Array,
    onClickTabItemIndex: Function,
  },
}
</script>

<style scoped lang="scss">

</style>
