<template>
  <div class="self-learning-mode-result-wrap">
    <V2Wrapper>
      <template v-if="this.errorMsg !== null">
        <ErrorMsgBox :contents="this.errorMsg"/>
      </template>
      <template v-else-if="this.isLoading">
        <LoadingBox style="margin-top: 30px"/>
      </template>
      <template v-else>
        <SelfLearningModeResult>
          <template v-slot:tab>
            <SelfLearningModeResultTab
              :tabs="this.tabs"
              :select-tab-index="this.selectTabIndex"
              :on-change-select-tab-index="(index) => this.selectTabIndex = index"
              :tab-items="this.tabItems"
              :on-click-tab-item-index="this.onClickTabItemIndex"
            />
          </template>
          <template v-slot:body>
            <SelfLearningModeResultBody
              :error-msg="this.bodyErrorMsg"
              :body-data="this.bodyData"
            />
          </template>
        </SelfLearningModeResult>
      </template>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <LoadingDialog :is-show="this.isLoadingDialog"/>
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import SelfLearningModeResult from "@/components/SelfLearningModeResult.vue";
import {mapGetters} from "vuex";
import SelfLearningModeResultTab from "@/components/SelfLearningModeResultTab.vue";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import AcademyService from "@/services/academy.service";
import SelfLearningModeResultBody from "@/components/SelfLearningModeResultBody.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
  name: 'TeacherSelfLearningModeResult',
  components: {
    LoadingDialog,
    SelfLearningModeResultBody,
    LoadingBox,
    ErrorMsgBox,
    SelfLearningModeResultTab,
    V2Wrapper,
    SelfLearningModeResult,
  },
  data() {
    return {
      isLoadingDialog: false,
      errorMsg: null,
      isLoading: true,
      selectTabIndex: 0,
      studentItems: [],
      selectStudentAcademyUserId: null,
      diagItems: [],
      selectDiagEvalStuId: null,
      unitItems: [],
      selectUnitEvalStuId: null,
      bodyErrorMsg: '왼쪽 학생 목록에서 학생을 먼저 선택해주세요',
      bodyData: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async onClickTabItemIndex(index) {
      this.selectDiagEvalStuId = null;
      this.selectUnitEvalStuId = null;

      switch (this.selectTabIndex) {
        case 0:
          const studentItem = this.studentItems[index];
          this.selectStudentAcademyUserId = studentItem.au_id;
          this.selectTabIndex = 1;
          this.bodyErrorMsg = '왼쪽 진단평가 혹은 단원평가 목록에서 평가 결과를 볼 항목을 선택해주세요';
          await this.loadDiagAndUnitItems();
          return;
        case 1:
          const diagItem = this.diagItems[index];
          this.selectDiagEvalStuId = diagItem.id;
          await this.loadBodyData();
          return;
        case 2:
          const unitItem = this.unitItems[index];
          this.selectUnitEvalStuId = unitItem.id;
          await this.loadBodyData();
          return;
      }
    },
    async loadBodyData() {
      this.isLoadingDialog = true;

      const selectDiagEvalStuId = this.selectDiagEvalStuId;
      const selectUnitEvalStuId = this.selectUnitEvalStuId;

      const res = await AcademyService.getTeacherSelfLearningResultBodyData(
        this.selectStudentAcademyUserId,
        selectDiagEvalStuId !== null ? 'diag' : 'unit',
          selectDiagEvalStuId !== null ? selectDiagEvalStuId : selectUnitEvalStuId,
      );
      if (res) this.bodyData = res.data;

      this.isLoadingDialog = false;
      this.bodyErrorMsg = null;
    },
    async loadDiagAndUnitItems() {
      this.isLoadingDialog = true;
      this.diagItems = [];
      this.unitItems = [];

      const res = await AcademyService.getTeacherSelfLearningResultDiagEvalList(this.selectStudentAcademyUserId);
      if (res) {
        this.diagItems = res.des_list ?? [];
        this.unitItems = res.ues_list ?? [];
      }

      this.isLoadingDialog = false;
    },
    async initData() {
      this.isLoading = true;

      const academyUserId = this.getUser?.academy_user?.id;
      if (academyUserId === null) { this.showErrorMsg('학원 ID를 알 수 없습니다'); return; }

      const selectedAcademyClassId = this.getSelectedAcademyClassId;
      if (selectedAcademyClassId === null) { this.showErrorMsg('반을 먼저 선택해주세요'); return; }

      const res = await AcademyService.getTeacherSelfLearningResultUsers(selectedAcademyClassId);
      if (!res) { this.showErrorMsg('반 학생 리스트를 불러오는 중 오류가 발생하였습니다'); return; }
      this.studentItems = res.result_list ?? [];

      this.isLoading = false;
    },
    showErrorMsg(errorMsg) {
      this.errorMsg = errorMsg;
      this.isLoading = false;
    },
  },
  computed: {
    tabs() {
      const resultList = ['학생목록'];
      if (this.selectStudentAcademyUserId !== null) {
        resultList.push('진단평가');
        resultList.push('단원평가');
      }
      return resultList;
    },
    tabItems() {
      const selectStudentAcademyUserId = this.selectStudentAcademyUserId;
      const selectDiagEvalStuId = this.selectDiagEvalStuId;
      const selectUnitEvalStuId = this.selectUnitEvalStuId;

      switch (this.selectTabIndex) {
        case 0: return this.studentItems.map(e => {
          return {title: e.nm, isActive: e.au_id === selectStudentAcademyUserId};
        });
        case 1: return this.diagItems.map(e => {
          return {title: e.nm, subTitle: e.dt, isActive: e.id === selectDiagEvalStuId};
        });
        case 2: return this.unitItems.map(e => {
          return {title: e.nm, subTitle: e.dt, isActive: e.id === selectUnitEvalStuId};
        });
      }
      return [];
    },
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('classStore', ['getSelectedAcademyClassId']),
  },
}
</script>
