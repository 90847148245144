<template>
  <div :class="['popup-wrap', 'analysis-detail-popup-wrap', { hide: !this.isVisible }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <template v-if="this.isLoading">
          <LoadingBox/>
        </template>
        <template v-else>
          <h1>{{ this.itemData.unit_name }}</h1>
          <div class="header-graph-list">
            <div class="gl-item gl-learning-rate-wrap">
              <div class="gli-title-wrap">
                <p>학습 참여율</p>
                <b-button class="s-tooltip"
                          v-b-tooltip.hover.html="{title: '해당 대단원 또는 중단원 별 학생들의 학습 참여율(과제를 참여한 학생 / 전체 학생)을 알 수 있습니다.'}">
                  <img src="@/assets/images/tooltip.png" alt="">
                </b-button>
              </div>
              <div class="graph-line-wrap">
                <div class="graph-wrap">
                  <div class="graph-outer-wrap">
                    <div class="graph-inner-wrap">
                      <DoughnutChart
                        :chartData="this.learningRateChartData"
                        :options="this.learningRateOption"
                        :style="[{'aspect-ratio': '1'}]"
                      />
                    </div>
                  </div>
                </div>
                <div class="graph-desc-wrap">
                  <div class="gd-item">
                    <div class="gdi-icon"></div>
                    <h5>참여</h5>
                    <p>{{ this.itemData.learn_part_rt.part_cnt }}명</p>
                    <span>({{ this.itemData.learn_part_rt.part_cnt_rt }}%)</span>
                  </div>
                  <div class="gd-item">
                    <div class="gdi-icon"></div>
                    <h5>미참여</h5>
                    <p>{{ this.itemData.learn_part_rt.none_part_cnt }}명</p>
                    <span>({{ this.itemData.learn_part_rt.none_part_cnt_rt }}%)</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="gl-item gl-learning-state-wrap">
              <div class="gli-title-wrap">
                <p>학습 상태</p>
                <b-button class="s-tooltip"
                          v-b-tooltip.hover.html="{
                            title: '해당 대단원 또는 중단원 별 학생들의 학습 상태를 알 수 있습니다.',
                          }">
                  <img src="@/assets/images/tooltip.png" alt="">
                </b-button>
              </div>
              <div class="graph-line-wrap">
                <div class="graph-wrap">
                  <div class="graph-outer-wrap">
                    <div class="graph-inner-wrap">
                      <DoughnutChart
                          :chartData="this.learningStatusChartData"
                          :options="this.learningStatusChartOption"
                          :style="[{'aspect-ratio': '1'}]"
                      />
                    </div>
                  </div>
                </div>
                <div class="graph-desc-wrap">
                  <div class="gd-item">
                    <div class="gdi-icon"></div>
                    <h5>성취 완료</h5>
                    <p>{{ this.itemData.learn_status.completed_cnt }}명</p>
                    <span>({{ this.itemData.learn_status.completed_cnt_rt }}%)</span>
                  </div>
                  <div class="gd-item">
                    <div class="gdi-icon"></div>
                    <h5>학습 진행중</h5>
                    <p>{{ this.itemData.learn_status.progress_cnt }}명</p>
                    <span>({{ this.itemData.learn_status.progress_cnt_rt }}%)</span>
                  </div>
                  <div class="gd-item">
                    <div class="gdi-icon"></div>
                    <h5>부족한 부분 발견</h5>
                    <p>{{ this.itemData.learn_status.week_cnt }}명</p>
                    <span>({{ this.itemData.learn_status.week_cnt_rt }}%)</span>
                  </div>
                  <div class="gd-item">
                    <div class="gdi-icon"></div>
                    <h5>학습 이력 없음</h5>
                    <p>{{ this.itemData.learn_status.no_cnt }}명</p>
                    <span>({{ this.itemData.learn_status.no_cnt_rt }}%)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vulnerable-wrap">
            <h2>🚨 취약한 학습 요소</h2>
            <div class="vw-list-wrap">
              <template v-for="(weakItem, index) in this.itemData.weak_items" :key="index">
                <div class="list-item">
                  <p><strong>{{ weakItem.week_cnt }}</strong>명의 학생이 취약해요</p>
                  <span>{{ weakItem.ptn_name }}</span>
                </div>
              </template>
            </div>
          </div>
          <template v-for="(studentStatusItem, index) in this.itemData.student_status_items" :key="index">
            <div class="student-list-wrap">
              <div class="sl-title-wrap">
                <div class="slt-icon" :style="[{'background-color': studentStatusItem.bg_color}]"></div>
                <p>{{ studentStatusItem.title }} ({{ studentStatusItem.studentItems.length }}명)</p>
              </div>
              <div class="sl-list-wrap">
                <template v-for="(studentItem, index) in studentStatusItem.studentItems" :key="index">
                  <div class="sll-item"
                       @click="() => this.$router.push({ name: 'TeacherSelfLearningModeResult' })">
                    <div class="item-img-box">
                      <img :src="studentItem.url" alt="">
                    </div>
                    <p>{{ studentItem.nm }}</p>
                    <span>{{ studentItem.prb_cnt }}문항</span>
                    <img class="item-arrow" src="@/assets/images/arrow-right-5.png" alt="">
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import {DoughnutChart} from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingDialog from "@/components/LoadingDialog.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import AcademyService from "@/services/academy.service";
import { debounce } from 'lodash'

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  components: {
    LoadingBox,
    LoadingDialog,
    DoughnutChart,
  },
  props: {
    isVisible: Boolean,
    onClose: Function,
    evalData: Object,
    chapterData: Object,
  },
  watch: {
    async isVisible() {
      if (this.isVisible) {
        await this.initData(this);
      } else {
        this.isLoading = true;
      }
    },
  },
  data() {
    const sampleNames = [
      '강기복',
      '도레미',
      '라분발',
    ];
    const sampleThumbnailUrls = [
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1520626337972-ebf863448db6?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ];

    return {
      isLoading: true,
      itemData: null,
      noLearningHistoryStudents: [
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: 0,
        },
      ],
      discoveredMissingStudents: [
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
      ],
      learningProgressStudents: [
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
    initData: debounce(async (appContext) => {
      appContext.isLoading = true;

      const params = {};
      const evalData = appContext.evalData;
      const chapterData = appContext.chapterData;
      if (evalData) {
        params['data_type'] = 'eval';
        params['is_diag'] = evalData.isDiag;
        params['eval_id'] = evalData.id;
      } else if (chapterData) {
        params['data_type'] = 'chapter';
        params['branch_name'] = chapterData.branchName;
        params['chapter_ptn_id'] = chapterData.chapterPtnId;
        params['academy_class_id'] = chapterData.academyClassId;
      }
      const res = await AcademyService.getTeacherDashboardAnalysisDetail(params);
      if (!res) appContext.$toast.error('정보를 불러오는 중 오류가 발생하였습니다');
      appContext.itemData = res.item_data;

      appContext.isLoading = false;
    }, 100),
    onCloseEvent() {
      this.onClose();
    },
  },
  computed: {
    learningRateChartData() {
      return {
        labels: ['참여', '미참여'],
        datasets: [
          {
            data: [
              this.itemData.learn_part_rt.part_cnt,
              this.itemData.learn_part_rt.none_part_cnt,
            ],
            backgroundColor: ['#008ED5', '#A4E1FF'],
          },
        ],
      };
    },
    learningRateOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    learningStatusChartData() {
      return {
        labels: [
          '성취 완료',
          '학습 진행중',
          '부족한 부분 발견',
          '학습 이력 없음',
        ],
        datasets: [
          {
            data: [
              this.itemData.learn_status.completed_cnt,
              this.itemData.learn_status.progress_cnt,
              this.itemData.learn_status.week_cnt,
              this.itemData.learn_status.no_cnt,
            ],
            backgroundColor: [
              '#006EA5',
              '#008ED5',
              '#FF6854',
              '#797979',
            ],
          },
        ],
      };
    },
    learningStatusChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
  },
}
</script>
