<template>
  <template v-if="this.errorMsg">
    <div class="sw-info-box">
      <ErrorMsgBox :header-title="'사용 안내'" :contents="this.errorMsg"/>
    </div>
  </template>
  <template v-else>
    <div class="sw-info-box">
      <h5>{{ this.bodyData?.student_name }} 학생</h5>
      <div class="swi-list-wrap">
        <div class="il-item">
          <p>단원명</p>
          <span>{{ this.bodyData?.unit_name }}</span>
        </div>
        <div class="il-item">
          <p>평가일</p>
          <span>{{ this.bodyData?.create_dt }}</span>
        </div>
        <div class="il-item">
          <p>정답수 / 문항수</p>
          <span>{{ this.bodyData?.tot_crt_cnt }}개 / {{ this.bodyData?.tot_prb_cnt }}개</span>
        </div>
        <div class="il-item">
          <p>풀이시간 / 평균 풀이시간</p>
          <span>{{ this.bodyData?.learn_time }} / {{ this.bodyData?.avg_learn_time }}</span>
        </div>
      </div>
    </div>
    <div class="sw-result-wrap">
      <div class="rw-result-box">
        <p>{{ this.bodyData?.eval_title }}</p>
        <div>
          <span>{{ this.bodyData?.eval_result }}</span>
        </div>
      </div>
      <div class="rw-weak-box">
        <p>사전 학습이 필요한 개념</p>
        <div class="wb-list-wrap">
          <template v-for="(weakItem, index) in (this.bodyData?.weak_ptns ?? [])" :key="index">
            <div class="wb-item"
                 @click="() => this.$router.push({ name: 'TeacherSelfLearningMode' })">
              <div class="i-contents">
                <span>{{ weakItem.badge }}</span>
                <p>{{ weakItem.name }}</p>
              </div>
              <img src="@/assets/images/weak.png" alt="">
            </div>
          </template>
        </div>
      </div>
      <div class="rw-map-box">
        <p>단원 학습 맵</p>
        <div class="mb-body-wrap">
          <UnitLearningMap
            :chart-data="this.bodyData?.learn_map"
          />
        </div>
      </div>
      <div class="rw-graph-box">
        <p>부족한 개념 학습 현황</p>
        <div class="gb-body-wrap">
          <div class="b-graph-wrap">
            <div class="gw-inner-wrap">
              <DoughnutChart
                :chartData="this.studyCompletionRateChartData"
                :options="this.studyCompletionRateChartOption"
                :style="[{'aspect-ratio': '1'}]"
              />
            </div>
            <div class="gw-contents">
              <div class="gwc-item">
                <div class="i-icon"
                     :style="[{'background-color': '#008ED5'}]"/>
                <p>학습 완료</p>
                <span :style="[{'color': '#008ED5'}]">{{ this.bodyData?.weak_graph_data?.pass_cnt }}개</span>
              </div>
              <div class="gwc-item">
                <div class="i-icon"
                     :style="[{'background-color': '#FF6854'}]"/>
                <p>학습 미완료</p>
                <span :style="[{'color': '#FF6854'}]">{{ this.bodyData?.weak_graph_data?.none_pass_cnt }}개</span>
              </div>
            </div>
          </div>
          <div class="b-divider"></div>
          <div class="b-contents-wrap">
            <p>미완료 개념</p>
            <div class="bc-items-wrap">
              <div v-for="(ptnNm, index) in this.bodyData?.weak_graph_data?.none_pass_ptn_nm_list" :key="index"
                   class="i-item">
                <div></div>
                <p>{{ ptnNm }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import UnitLearningMap from "@/components/UnitLearningMap.vue";
import {Chart, registerables} from "chart.js";
import {DoughnutChart} from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  components: {
    ErrorMsgBox,
    UnitLearningMap,
    DoughnutChart,
  },
  props: {
    errorMsg: String,
    bodyData: Object,
  },
  computed: {
    studyCompletionRateChartData() {
      return {
        labels: ['학습완료', '학습미완료'],
        datasets: [
          {
            data: [
              this.bodyData?.weak_graph_data?.pass_cnt,
              this.bodyData?.weak_graph_data?.none_pass_cnt,
            ],
            backgroundColor: ['#008ED5', '#FF6854'],
          },
        ],
      };
    },
    studyCompletionRateChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
  },
}
</script>

<style scoped lang="scss">

</style>
