<template>
  <table class="table">
    <tbody>
    <tr>
      <td :colspan="chartData.children && chartData.children.length ? chartData.children.length * 2 : null">
        <div :class="['chart-node', {'node-title': !('bg' in chartData) && !('ps' in chartData), 'node-weak': chartData.ps === false, 'node-pass': chartData.ps === true}]"
             style="min-width: 9rem"
             :id="chartData.ep_id"
             @click.stop="handleClick(chartData)">
          <slot :node-data="chartData">
            <div v-if="'bg' in chartData && 'ps' in chartData"
                 class="ic-header-wrap">
              <p v-if="'bg' in chartData">{{ chartData.bg }}</p>
              <span v-if="'ps' in chartData">
                <template v-if="chartData.ps === false">부족</template>
                <template v-else-if="chartData.ps === true">통과</template>
              </span>
            </div>
            <p>{{ chartData.nm }}</p>
          </slot>
        </div>
      </td>
    </tr>
    <template v-if="chartData.children && chartData.children.length">
      <tr class="chartLines">
        <td :colspan="chartData.children.length * 2">
          <div class="chartDownLine"></div>
        </td>
      </tr>
      <tr class="chartLines">
        <td class="chartRightLine"></td>
        <template v-for="n in chartData.children.length - 1" v-bind:key="n">
          <td class="chartLeftLine chartTopLine"></td>
          <td class="chartRightLine chartTopLine"></td>
        </template>
        <td class="chartLeftLine"></td>
      </tr>
      <tr class="nodes">
        <td colspan="2" v-for="child in chartData.children" :key="child.ep_id">
          <OrganizationChartNode :chart-data="child" :handle-click="handleClick">
            <template
                v-for="slot in Object.keys($slots)"
                v-slot:[slot]="scope">
              <slot :name="slot" v-bind="scope" />
            </template>
          </OrganizationChartNode>
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "OrganizationChartNode",
  props: {
    chartData: Object,
    handleClick: Function,
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";
$lineColor: rgb(179, 179, 179);

.table {
  --bs-body-bg: #F0F0F0;
  --bs-border-width: 0;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  border-collapse: separate;
  .chart-node {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    margin: 0 5px 2px;
    max-width: 20px;
    border: 1px solid #{$lineColor};
    text-align: center;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;
    gap: 5px;
    .ic-header-wrap {
      width: 100%;
      display: flex;
      gap: 8px;
      align-items: center;
      &>p {
        flex: 1;
        font-weight: 500;
        font-size: 10px;
        color: #797979;
        text-align: start;
      }
      &>span {
        border-radius: 3px;
        padding: 3px 5px;
        font-weight: 600;
        font-size: 10px;
        color: #fff;
        line-height: 1;
      }
    }
    &>p {
      font-weight: 600;
      font-size: 11px;
      color: #000;
      text-align: start;
      min-height: 25px;
    }
    &.node-title {
      border-color: #{$defaultColor};
      padding: 5px 10px;
      &>p {
        text-align: center;
        min-height: unset;
      }
    }
    &.node-weak {
      cursor: pointer;
      border-color: #faa296;
      .ic-header-wrap {
        &>span {
          background-color: #faa296;
        }
      }
      &:hover {
        box-shadow: 0 0 5px #f56868;
        z-index: 20;
      }
    }
    &.node-pass {
      border-color: #dbdbdb;
      .ic-header-wrap {
        &>span {
          background-color: #a4e1ff;
        }
      }
    }
  }
  .chartLines {
    height: 1.25rem;
    .chartDownLine {
      background: #{$lineColor};
      margin-left: auto;
      margin-right: auto;
      height: 1.25rem;
      width: 0.125rem;
      float: none;
    }
    .chartTopLine {
      border-top-color: #{$lineColor};
      border-top-style: solid;
      border-top-width: 2px;
    }
    .chartRightLine {
      border-right-color: #{$lineColor};
      border-right-style: solid;
      border-right-width: 1px;
    }
    .chartLeftLine {
      border-left-color: #{$lineColor};
      border-left-style: solid;
      border-left-width: 1px;
    }
  }
}
</style>
