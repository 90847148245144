<template>
  <div class="sslmrw-inner">
    <div class="v2-base-container">
      <div class="top-bg-wrap">
        <div class="sec-l-wrap">
          <slot name="tab"/>
        </div>
        <div class="sec-r-wrap">
          <slot name="body"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
