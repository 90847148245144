<template>
  <div class="lesson-board-wrap">
    <V2Wrapper>
      <div class="tlw-inner">
        <template v-if="this.isLoading">
          <div class="wi-loading">
            <b-spinner style="width: 3rem; height: 3rem;"
                       variant="primary"
                       label="Spinning"/>
          </div>
        </template>
        <template v-else>
          <div class="wi-body-wrap">
            <div :class="['side-nav-expand-btn', {'expand': this.isSideNavExpand}]"
                 @click="() => this.isSideNavExpand = true">
              <img class="expand-arrow-btn"
                   src="@/assets/images/arrow-right-9.png"
                   alt="">
            </div>
            <div :class="['side-nav-wrap', {'expand': this.isSideNavExpand}]">
              <div class="sn-header-wrap"
                   @click="() => this.isSideNavExpand = false">
                <p>모든 페이지</p>
                <img class="expand-arrow-btn"
                     src="@/assets/images/arrow-left-4.png"
                     alt="">
              </div>
              <div class="sn-body-wrap">
                <template v-for="(menuItems, sharedIndex) in [this.filteredSharedMenuItems, this.filteredMenuItems]" :key="sharedIndex">
                  <div class="bw-header-wrap">
                    <p>{{ sharedIndex == 0 ? '공유된 페이지' : '개인 페이지' }}</p>
                  </div>
                  <div class="menu-item-list">
                    <template v-for="(menuItem, index) in menuItems" :key="index">
                      <div :class="['menu-item', `d-${menuItem.depth}`, {'active': menuItem.id === this.selectMenuId, 'fold': menuItem.isFold, 'hide': menuItem.isHide}]"
                           @click="() => this.onClickMenu(menuItem.id)"
                           @contextmenu="this.onClickRightMenu($event, menuItem, sharedIndex == 0)">
                        <div class="li-menu-icon"
                             onclick="event.stopPropagation();"
                             @click="() => this.onClickMenuIcon(menuItem)">
                          <img src="@/assets/images/file.png" alt=""/>
                          <img src="@/assets/images/arrow-right-7.png" alt=""/>
                        </div>
                        <p class="li-text">{{ menuItem.title }}</p>
                        <div class="li-hover-btn-list">
                          <template v-if="sharedIndex != 0">
                            <button @click="() => this.onClickEditMenu(menuItem)"
                                    onclick="event.stopPropagation();">
                              <img src="@/assets/images/edit-2.png" alt="">
                            </button>
                          </template>
                          <template v-if="sharedIndex != 0 && menuItem.depth < 3">
                            <button @click="() => this.onClickAddMenu(menuItem.id, null)"
                                    onclick="event.stopPropagation();">
                              <img src="@/assets/images/plus-2.png" alt="">
                            </button>
                          </template>
                        </div>
                      </div>
                    </template>
                    <template v-if="sharedIndex != 0">
                      <div class="menu-item"
                           @click="() => this.onClickAddMenu(null, null)">
                        <img class="li-plus-icon" src="@/assets/images/plus.png" alt="">
                        <p class="li-text">페이지 추가</p>
                      </div>
                    </template>
                  </div>
                </template>
                <div class="user-item-list">
                  <template v-for="(userItem, index) in this.userItems" :key="index">
                    <div class="user-item">
                      <div class="liu-thumb">
                        <img src="@/assets/images/user-empty-thumbnail.png" alt=""/>
                      </div>
                      <p>{{ userItem.name }}</p>
                      <div :class="['liu-online', {'active': userItem.isOnline}]"></div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="v2-base-container">
              <div :class="['bc-loading', {'active': this.isLoadingContents}]">
                <b-spinner
                    style="width: 3rem; height: 3rem;"
                    variant="primary"
                    label="Spinning"
                ></b-spinner>
              </div>
              <div :class="['bc-empty-text', {
                'active': this.isLoadingContents === false && this.selectMenuId === null,
              }]">페이지를 먼저 선택해주세요.</div>
              <div :class="['bc-empty-text', {
                'active': this.isLoadingContents === false && this.isContentsNotFound,
              }]">해당 메뉴를 찾을 수 없습니다.</div>
              <div :class="['bc-inner-wrap', {
                'active': this.isLoadingContents === false && this.selectMenuId !== null,
              }]">
                <div id="editorjs"
                     ref="editorJsWrap"
                ></div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <EditMenuPopup
        :edit-menu="this.showEditMenu"
        :on-close="() => this.showEditMenu = null"
        :on-change="this.onChangeBoardHeaderTitle"
    />
    <RightMenu
        :is-visible="this.rightMenuId !== null"
        :on-close="() => this.hideRightMenu()"
        :client-x="this.rightMenuClientX ?? 0"
        :client-y="this.rightMenuClientY ?? 0"
        :items="this.rightMenuItems"
    />
    <ShareMenuPopup
        :is-visible="(this.shareMenuPopupObj?.id ?? null) !== null"
        :on-close="() => this.shareMenuPopupObj = null"
        :menu-id="this.shareMenuPopupObj?.id"
        :menu-title="this.shareMenuPopupObj?.title ?? ''"
    />
    <ImportMenuPopup
        :is-visible="(this.importMenuPopupObj?.id ?? null) !== null"
        :on-close="() => this.importMenuPopupObj = null"
        :menu-id="this.importMenuPopupObj?.id"
        :menu-title="this.importMenuPopupObj?.title ?? ''"
    />
    <LoadingDialog :is-show="this.isLoadingDialog"/>
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import BoardService from "@/services/board.service";
import ProblemSuggestPopup from "@/components/ProblemSuggestPopup.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";
import { debounce } from 'lodash'
import EditMenuPopup from "@/components/EditMenuPopup.vue";
import utils from "@/utils";
import RightMenu from "@/components/RightMenu.vue";
import {mapGetters} from "vuex";
import ShareMenuPopup from "@/components/ShareMenuPopup.vue";
import {getEditorJsInlineToolbar} from "@/utils/editor-js";
import ImportMenuPopup from "@/components/ImportMenuPopup.vue";

export default {
  components: {
    ImportMenuPopup,
    ShareMenuPopup,
    RightMenu,
    EditMenuPopup,
    LoadingDialog,
    ProblemSuggestPopup,
    V2Wrapper,
  },
  props: {
    userItems: Array,
  },
  watch: {
    $route (to, from) {
      const selectMenuId = parseInt(to.query.menuId ?? null);
      this.selectMenuId = isNaN(selectMenuId) ? null : selectMenuId;
    },
    async selectMenuId() {
      this.isLoadingContents = true;
      this.$refs.editorJsWrap.innerHTML = '';
      const selectMenuId = this.selectMenuId;

      if (selectMenuId !== null) {
        const res = await BoardService.getBoardContents(selectMenuId);
        this.isContentsNotFound = !res.is_exist;
        if (res.is_exist) {
          const targetEditorId = ++this.editorId;
          const boardContentsId = res.id;
          const initContents = res.contents.length > 0 ? JSON.parse(res.contents) : {};
          this.isReadOnly = res.is_read_only;

          const editor = new window.EditorJS({
            holder: 'editorjs',
            autofocus: true,
            minHeight: 30,
            inlineToolbar: utils.getEditorJsInlineToolbar(),
            data: initContents,
            placeholder: this.isReadOnly ? '' : '여기 내용을 입력해주세요.',
            readOnly: this.isReadOnly,
            onReady: () => {},
            onChange: async (api, event) => {
              await this.onBoardChangeEvent(targetEditorId, editor, boardContentsId);
            },
            tools: utils.getEditorJsTools(),
          });
        }
      }
      this.isLoadingContents = false;
    },
  },
  data() {
    return {
      editorId: 0,
      isLoading: true,
      isLoadingDialog: false,
      isLoadingContents: true,
      isContentsNotFound: false,
      isSideNavExpand: false,
      isReadOnly: false,

      showEditMenu: null,
      selectMenuId: null,
      sharedMenuItems: [],
      menuItems: [],
      foldMenuIds: [],
      shareMenuPopupObj: null,
      importMenuPopupObj: null,

      // right menu
      rightMenuId: null,
      rightMenuClientX: null,
      rightMenuClientY: null,
      rightMenuItems: [],
    };
  },
  mounted() {
    utils.loadEditorJsScripts(this).then(() => {
      this.initData();
    });
  },
  methods: {
    addFoldMenuByMenuItems(menuItems) {
      const foldMenuIds = [];
      menuItems.forEach(e => {
        foldMenuIds.push(e.id);
        (e.sub_items ?? []).forEach(e => { // d1
          foldMenuIds.push(e.id);
          (e.sub_items ?? []).forEach(e => { // d2
            foldMenuIds.push(e.id);
            (e.sub_items ?? []).forEach(e => { // d3
              foldMenuIds.push(e.id);
            });
          });
        });
      });
      this.foldMenuIds = [...new Set([...this.foldMenuIds, ...foldMenuIds])];
    },
    onClickMenuIcon(menuItem) {
      const menuId = menuItem.id;
      const foldMenuIds = this.foldMenuIds;

      if (menuItem.isEmpty) {
        this.onClickMenu(menuId);
        return;
      }

      if (foldMenuIds.includes(menuId)) {
        this.foldMenuIds = foldMenuIds.filter((e) => e != menuId);
      } else {
        this.foldMenuIds.push(menuId);
      }
    },
    onClickEditMenu(menuItem) {
      this.showEditMenu = Object.assign({}, menuItem);
    },
    hideRightMenu() {
      this.rightMenuId = null;
    },
    onClickRightMenu(event, menuItem, isShared) {
      event.preventDefault();

      this.rightMenuClientX = event.clientX;
      this.rightMenuClientY = event.clientY;
      this.rightMenuId = menuItem.id;

      const items = [];
      if (isShared === false) {
        items.push({
          title: '이름수정',
          onClick: () => {
            this.hideRightMenu();
            this.onClickEditMenu(menuItem);
          },
        });
      }
      if (isShared === false) {
        items.push({
          title: '메뉴삭제',
          onClick: () => {
            this.hideRightMenu();
            this.onClickDeleteMenu(menuItem.id);
          },
        });
      }
      if (isShared === false) {
        if (menuItem.depth < 3) {
          items.push({
            title: '하위메뉴추가',
            onClick: () => {
              this.hideRightMenu();
              this.onClickAddMenu(menuItem.id, null);
            },
          });
        }
      }
      if (isShared === false) {
        items.push({
          title: '공유하기',
          onClick: () => {
            this.hideRightMenu();
            this.onClickShareMenu(menuItem);
          },
        });
      }
      if (isShared) {
        items.push({
          title: '가져오기',
          onClick: () => {
            this.hideRightMenu();
            this.onClickImportMenu(menuItem);
          },
        });
      }
      items.push({
        title: '공유페이지 링크복사',
        onClick: () => {
          const shareLink = this.$router.resolve({name: 'LessonShare', params: {menuId: menuItem.id}}).href;
          const shareFullLink = `${window.location.origin}${shareLink}`;
          utils.writeClipboardText(shareFullLink);
          this.$toast.success('링크가 복사되었습니다');
          this.hideRightMenu();
        }
      });
      this.rightMenuItems = items;
    },
    async onBoardChangeEvent(targetEditorId, editor, boardContentsId) {
      if (targetEditorId != this.editorId) return;
      if (this.isReadOnly) return;

      const contents = await editor.save();
      const encodedContents = JSON.stringify(contents);
      await BoardService.editBoardContents(boardContentsId, encodedContents);
    },
    async initData() {
      this.foldMenuIds = [];

      await Promise.all([
        BoardService.getBoardSharedMenus().then(res => {
          this.sharedMenuItems = res;
          this.addFoldMenuByMenuItems(res);
        }),
        BoardService.getBoardMenus().then(res => {
          this.menuItems = res;
          this.addFoldMenuByMenuItems(res);
        }),
      ]);

      setTimeout(() => {
        const selectMenuId = parseInt(this.$route.query.menuId ?? null);
        this.selectMenuId = isNaN(selectMenuId) ? null : selectMenuId;
        this.isLoadingContents = false;
      }, 500);

      this.isLoading = false;
    },
    async onClickAddMenu(upperMenuId, title) {
      this.isLoadingDialog = true;

      const res = await BoardService.createMenu(upperMenuId, title);
      this.menuItems = res ?? [];

      this.isLoadingDialog = false;
    },
    async onClickShareMenu(menuItem) {
      this.shareMenuPopupObj = {'id': menuItem.id, 'title': menuItem.title};
    },
    async onClickImportMenu(menuItem) {
      this.importMenuPopupObj = {'id': menuItem.id, 'title': menuItem.title};
    },
    async onClickDeleteMenu(menuId) {
      this.isLoadingDialog = true;

      const res = await BoardService.deleteMenu(menuId);
      this.menuItems = res ?? [];

      if (menuId === this.selectMenuId) {
        this.selectMenuId = null;
      }

      this.isLoadingDialog = false;

      this.onClickMenu(null);
    },
    onClickMenu(menuId) {
      const query = Object.assign({}, this.$route.query);
      if (menuId === null) {
        delete query.menuId;
      } else {
        query.menuId = menuId;
      }
      this.$router.push({ name: this.$route.name, query: query });

      if (this.foldMenuIds.includes(menuId)) {
        this.foldMenuIds = this.foldMenuIds.filter((e) => e != menuId);
      }
    },
    onChangeBoardHeaderTitle(menuId, value) {
      this.boardHeaderTitleSaveToServer(this, menuId, value);
    },
    boardHeaderTitleSaveToServer: debounce(async (appContext, menuId, title) => {
      const res = await BoardService.editMenu(menuId, title);
      appContext.menuItems = res ?? [];
    }, 200),
    parseMenuItemList(menuItems, foldMenuIds) {
      const resultList = [];

      for (const menuItem of menuItems) {
        const d1Items = menuItem.sub_items ?? [];
        let isFold = foldMenuIds.includes(menuItem.id);
        let isD0Fold = isFold;
        resultList.push({
          depth: 0,
          id: menuItem.id,
          title: menuItem.title,
          isFold: isFold,
          isHide: false,
          isEmpty: d1Items.length === 0,
        });

        for (const d1Item of d1Items) {
          const d2Items = d1Item.sub_items ?? [];
          isFold = foldMenuIds.includes(d1Item.id);
          let isD1Fold = isFold;
          resultList.push({
            depth: 1,
            id: d1Item.id,
            title: d1Item.title,
            isFold: isFold,
            isHide: isD0Fold,
            isEmpty: d2Items.length === 0,
          });

          for (const d2Item of d2Items) {
            const d3Items = d2Item.sub_items ?? [];
            isFold = foldMenuIds.includes(d2Item.id);
            let isD2Fold = isFold;
            resultList.push({
              depth: 2,
              id: d2Item.id,
              title: d2Item.title,
              isFold: isFold,
              isHide: isD0Fold || isD1Fold,
              isEmpty: d3Items.length === 0,
            });

            for (const d3Item of d3Items) {
              isFold = foldMenuIds.includes(d3Item.id);
              resultList.push({
                depth: 3,
                id: d3Item.id,
                title: d3Item.title,
                isFold: isFold,
                isHide: isD0Fold || isD1Fold || isD2Fold,
                isEmpty: true,
              });
            }
          }
        }
      }

      return resultList;
    },
  },
  computed: {
    filteredSharedMenuItems() {
      const filteredMenuItems = this.filteredMenuItems;
      return this.parseMenuItemList(this.sharedMenuItems, this.foldMenuIds).filter(e => {
        return filteredMenuItems.findIndex(e2 => e2.id == e.id) < 0;
      });
    },
    filteredMenuItems() {
      return this.parseMenuItemList(this.menuItems, this.foldMenuIds);
    },
    ...mapGetters('auth', [
      'getUser',
    ]),
  },
}
</script>

<style scoped lang="scss">
</style>
