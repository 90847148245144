<template>
  <div class="teacher-lesson-share-wrap">
    <template v-if="this.isLoading">
      <LoadingBox style="padding: 40px"/>
    </template>
    <template v-else-if="this.errorMsg !== null">
      <ErrorMsgBox :contents="this.errorMsg"/>
    </template>
    <template v-else>
      <div class="tls-title-wrap">
        <span>{{ this.menuTitle }}</span>
      </div>
      <div class="tls-body-wrap">
        <div id="editorjs"></div>
      </div>
    </template>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import utils from "@/utils";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import BoardService from "@/services/board.service";

export default {
  components: {
    ErrorMsgBox,
    LoadingBox,
    V2Wrapper,
  },
  data() {
    return {
      isLoading: true,
      errorMsg: null,
      menuId: null,
      menuTitle: '',
      initContents: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      const { menuId } = this.$route.params;
      utils.debugLog(`menuId: ${menuId}`);

      if (!menuId) {
        this.errorMsg = '잘못된 메뉴 아이디입니다';
        this.isLoading = false;
        return;
      }

      const menuIdNum = parseInt(menuId);
      if (isNaN(menuIdNum)) {
        this.errorMsg = '메뉴 아이디 형식을 확인해주세요';
        this.isLoading = false;
        return;
      }

      await utils.loadEditorJsScripts(this);

      try {
        const res = await BoardService.getBoardContents(menuIdNum);
        utils.debugLog(res);

        this.menuTitle = res.menu_title;
        if (res.is_exist) {
          this.initContents = res.contents.length > 0 ? JSON.parse(res.contents) : {};
        }
      } catch (e) {
        this.errorMsg = '내용을 불러오는 중 오류가 발생하였습니다';
        this.isLoading = false;
        return;
      }

      this.isLoading = false;

      this.initEditor();
    },
    initEditor() {
      const initContents = this.initContents;

      new window.EditorJS({
        holder: 'editorjs',
        autofocus: true,
        minHeight: 30,
        inlineToolbar: utils.getEditorJsInlineToolbar(),
        data: initContents,
        placeholder: '',
        readOnly: true,
        onReady: () => {},
        tools: utils.getEditorJsTools(),
      });
    },
  },
  computed: {},
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.teacher-lesson-share-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .tls-title-wrap {
    width: 100%;
    height: #{$v2HeaderTabletHeight};
    border-bottom: #{$v2HeaderLineSize} solid #{$v2HeaderLineColor};
    display: flex;
    justify-content: center;
    align-items: center;
    &>span {
      font-weight: 600;
      font-size: 13px;
      color: black;
    }
  }
  .tls-body-wrap {
    width: 100%;
    flex: 1;
    overflow: auto;
    padding: 50px 30px;
    @include mobile {
      padding: 50px 15px;
    }
  }
}
</style>
